$font-default: 'Roboto';

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.passing-line-through {
  left: 93%;
}

.mobile-line {
  margin-top: -9%;
}

.register {
  width: 460px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // animation: fadeOutIn 1s linear forwards;
  // animation: fadeIn 1s;

  .topBanerSection {
    padding-top: 0.15%;
    padding-bottom: 0.15%;

    .left {
      align-items: center;
    }
  }

  .switch {
    position: relative;
    // transition: all 0.2s ease-in-out;
    float: right;
    top: -63px;
    right: 30%;
    &:hover {
      transform: scale(1.1);
      text-decoration: none;
    }
  }

  .switch-content {
    font-size: 15px;
    font-family: 'Roboto';
    text-align: left;
    color: #b5b5b5;
    position: relative;
    top: -34px;
    right: -70px;
    &:hover {
      text-decoration: none;
    }
  }

  .sign-up-error {
    font-size: 12px;
    color: red;
    margin-top: 15px;
    text-align: center;
    font-family: 'Roboto';
    background-color: #ffe2e2;
    width: 100%;
    padding: 12px 15px;
    text-align: left;
    border-radius: 5px;
  }

  .sign-up-error i {
    float: right;
    font-size: 16px;
    margin-top: -1%;
    font-family: 'Helvetica', 'Arial', sans-serif;
    &:hover {
      cursor: pointer;
    }
  }

  .close {
    display: none;
  }

  .sign-in-with-google {
    text-align: center;
    margin-bottom: 12px !important;
    width: 100%;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .group {
    margin-top: 20px;
    position: relative !important;
  }
  .register-password-icon:hover {
    cursor: pointer;
  }

  .sign-up-error {
    font-family: $font-default !important;
    font-size: 12px;
    color: red;
    float: right;
  }

  .form-input {
    margin: 12px auto;
    margin-top: 0 !important;
    width: 100%;
    text-align: left;
    margin-bottom: 0 !important;
    .btn-group {
      display: flex;
      margin-bottom: 28px;

      .btn {
        border-width: 2px;
        padding-left: 2rem;
        padding-right: 2rem;
        border: 1px solid rgba(212, 212, 212, 1);
        font-weight: 400 !important;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #3e4c63;
        &:hover {
          background: #3e4c63;
          color: #fff;
          border-color: transparent;
        }
        @media (min-width: 320px) and (max-width: 425px) {
          padding-left: 16px !important;
          padding-right: 16px !important;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .btn:nth-child(1) {
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        border-right-width: 0px;
      }
      .btn:nth-child(3) {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        border-left-width: 0px;
      }
      .active-btn {
        border-color: transparent;
        background-color: #3e4c63;
        color: white;
      }
    }
    .custom-google-login {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
    }
  }
  .ant-divider-with-text {
    margin: 0 !important;
  }

  .title {
    font-family: $font-default !important;
    font-size: 32px;
    margin: 25px 0px;
    font-weight: 450;
  }

  .responsive-width {
    font-family: $font-default !important;
    font-size: 1vh;
    margin-bottom: 10px;

    span {
      padding: 0px 5px;
    }
  }

  .container {
    text-align: center;
    .or:after,
    .or:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0' !important;
      color: #70707085;
      text-decoration: line-through;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .sign-up {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #162642;
    // margin: 4px;
    margin: 15px auto 4px;
    width: 100%;
    height: 46px !important;
    border: none;
    background-color: #162642 !important;
    box-shadow: none !important;
    color: white !important;
    padding: 10px 28px;
    font-size: 16px;
    text-align: center;
    font-weight: 700 !important;
    font-family: $font-default !important;
    transition: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    .ant-btn-loading-icon {
      margin-right: 14px;
      .anticon {
        padding-right: 0;
      }
    }
    &:hover {
      transform: unset;
      background-color: #162642;
    }
    &:focus {
      box-shadow: unset;
      border-color: transparent;
    }
  }

  .sign-up[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: rgba(22, 38, 66, 0.39);
  }

  .remember-section {
    label {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #7a8393;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #7c8e54;
      border: none;
    }
    .ant-checkbox-inner {
      border-color: #888 !important;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
  }

  .agreement-policy {
    text-align: center;
    display: block;
    margin: 8px auto 0%;
    width: 80%;
    font-size: 14px;
    font-family: $font-default !important;
    a {
      color: #1d6ac5 !important;
    }
  }
  .linTextRecruit {
    margin: 20px auto 10px;
    color: #b5b5b5;
  }

  .form-field {
    margin-bottom: 2px !important;
    margin-top: 20px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-default !important;
    &:first-child {
      margin-top: 8px !important;
    }
  }

  input::placeholder {
    font-size: 16px;
    font-family: $font-default !important;
  }

  .text-field-input {
    border: 1px solid rgba(117, 109, 109, 0.299);
    width: 100%;
    height: unset !important;
    padding: 8px !important;
    border-radius: 5px;
    font-size: 16px;
    font-family: $font-default !important;
    &.name-input {
      text-transform: none;
    }
  }

  .passwordInput {
    padding-right: 32px !important;
  }

  .error {
    border: 1px solid red;
    text-align: left;
  }

  .google-login {
    // box-shadow: none !important;
    // border: 1px solid rgba(211, 211, 211, 0.7) !important;
    // border-radius: 5px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
    font-family: $font-default !important;
  }

  .google-text {
    font-size: 16px;
    color: #333333 !important;
    font-weight: 400 !important;
    line-height: 19px;
    font-family: $font-default !important;
  }
}

@media only screen and (max-height: 900px) and (max-width: 1440px) {
  .form-input {
    .form-field {
      padding: 1px 0 !important;
      margin: 13px 0 4px !important;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .container {
    // text-align: center;
    .or:after,
    .or:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0' !important;
      text-decoration: line-through;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .form-input {
    max-width: 425px;
  }
}

@media only screen and (min-width: 1025px) {
  .title {
    font-size: 32px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .register {
    width: 370px;
    .switch {
      top: -63px !important;
      right: 25% !important;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .register {
    .switch {
      top: -58px !important;
      right: 20% !important;
    }
  }
}

@media only screen and (max-width: 769px) {
  .passing-line-through {
    left: 90% !important;
  }

  .title {
    font-size: 32px;
    margin: 25px 0px !important;
  }

  .agreement-policy {
    text-align: center;
    display: block;
    margin: 5% auto 0%;
    width: 75% !important;
  }

  .register {
    .switch {
      top: -58px !important;
      right: 15% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .register {
    width: 100%;
    .form-input,
    .container {
      position: unset !important;
      // top: -32px !important;
      // margin-top: 25px !important;
    }
    .ant-divider-horizontal.ant-divider-with-text {
      padding: 0 30px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 520px) {
  .register {
    .switch {
      top: -55px !important;
      right: 15px !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  // .title {
  //   font-size: 26px !important;
  // }

  .register {
    // animation: fadeOutIn 1s linear forwards;

    .sign-up-error {
      font-size: 18px !important;
    }

    .title {
      font-size: 32px;
      margin: 25px 0px;
    }

    .responsive-width {
      font-size: 1vh;
      margin-bottom: 10px;

      span {
        padding: 0px 5px;
      }
    }

    .container {
      text-align: center;
      .or:after,
      .or:before {
        content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
        text-decoration: line-through;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 280px) {
  .register {
    .switch {
      top: -90px !important;
    }
    .container {
      .or:after,
      .or:before {
        content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
        text-decoration: line-through;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .agreement-policy {
    width: 95% !important;
    font-size: 13px !important;
  }
}
