div.slide-deck-modal-contents {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

h5.upload-modal-title {
  font-weight: bold;
  margin-bottom: 0rem;
}

p.upload-modal-description {
  margin-bottom: 0rem;
  font-size: 1.2rem;
}
.uploadFailErr {
  font-size: large;
  /* font-weight: normal; */
  font-family: Roboto;
}