.welcomeback-container {
  height: 630px;
  width: 1180px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Roboto;
}

.welcomeback-content {
  height: 545px;
}
.content-hi {
  color: #162642;
  font-size: 50px;

  text-align: center;
  padding-top: 200px;
}
.welcomeback {
  color: #162642;
  font-size: 40px;

  text-align: center;
}
.newdeals {
  font-size: 25px;

  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}

.checkout {
  font-size: 25px;

  text-align: center;
}
.welcomeback-bottom {
  height: 85px !important;
  border-radius: 0px 0px 10px 10px !important;
  text-align: center !important;
  background-color: #162642 !important;
  color: white !important;
  font-size: 26px;
  line-height: 85px;
  font-weight: bold;
}

.first-invite-container {
  height: 630px;
  width: 1180px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Roboto;
}

.first-invite-content {
  height: 545px;
}
.content-congratulations {
  color: #162642;
  font-size: 50px;

  text-align: center;
  padding-top: 150px;
}
.invited-first-startup {
  color: #162642;
  font-size: 40px;

  text-align: center;
}
.also-want {
  font-size: 25px;

  text-align: center;

  padding-top: 50px;
}

.send-intro {
  font-size: 25px;

  text-align: center;
  margin-bottom: 70px;
}

.logo-center {
  height: 120px;
  width: auto;
}

.first-invite-button {
  border: 1px solid #162642;
  width: 120px;
  height: 50px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: #162642;
  font-family: Roboto;
  font-weight: 700;
}
.got-it {
  color: #162642;
}
.got-it:hover {
  color: #162642;
  text-decoration: none;
}

.second-invite-container {
  height: 630px;
  max-width: 1180px;
  width: 90%;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #edc432;
  font-family: Roboto;
  border-radius: 10px;
}

.second-invite-content {
  height: 545px;
}
.connection-created {
  color: #162642;
  font-size: 50px;

  text-align: center;
  padding-top: 80px;
}
.want-to-connect {
  color: #162642;
  font-size: 40px;

  text-align: center;
}
.check-email {
  font-size: 25px;

  text-align: center;

  padding-top: 200px;
}

.check-email-text {
  font-size: 25px;
  text-align: center;
}

.excited {
  font-size: 25px;

  text-align: center;
  margin-bottom: 20px;
}

.second-invite-button {
  border: 1px solid #162642;
  width: 200px;
  height: 50px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: #162642;
  font-family: Roboto;
  font-weight: 700;
}
.discovering {
  color: #162642;
}
.discovering:hover {
  color: #162642;
  text-decoration: none;
}

.after-invite-container {
  height: 630px;
  width: 1180px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Roboto;
}

.after-invite-content {
  height: 545px;
}
.thats-it {
  color: #162642;
  font-size: 50px;

  text-align: center;
  padding-top: 120px;
}
.check-back {
  color: #162642;
  font-size: 40px;

  text-align: center;
  margin-top: 10px;
}
.allow-us {
  font-size: 25px;

  text-align: center;

  padding-top: 60px;
}

.show-startups {
  font-size: 25px;

  text-align: center;
  margin-bottom: 60px;
}

.after-invite-button {
  border: 1px solid #162642;
  width: 200px;
  height: 50px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  background-color: #162642;
  font-family: Roboto;
  font-weight: 700;
  color: white;
}
.complete-profile {
  color: white;
}
.complete-profile:hover {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .second-invite-container {
    height: 100%;
    max-height: 500px;
    position: relative;
    margin-top: -20px;
  }

  .connection-created {
    padding-top: 60px;
    font-size: 30px;
  }

  .want-to-connect {
    font-size: 26px;
  }

  .logo-center {
    height: 100px;
  }

  .check-email-text,
  .excited {
    font-size: 16px;
  }

  .discovering {
    font-size: 14px;
  }
}

@media only screen and (max-width: 360px) {
  .second-invite-container {
    height: 100%;
    max-height: 450px;
  }

  .connection-created {
    font-size: 25px;
  }

  .want-to-connect {
    font-size: 23px;
  }

  .logo-center {
    height: 80px;
  }

  .check-email-text,
  .excited {
    font-size: 15px;
  }

  .discovering {
    font-size: 13px;
  }
}
