.invite-code-wrapper {
  .heading-question {
    font-weight: 400;
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 0;
    color: #504f4f;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
    }
    .font-weight-bold {
      font-weight: 500;
    }
  }

  .heading-question-small {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #5d6166;
    margin-top: 15px;
    text-align: center;
  }
}

.inviteSpinner i.ant-spin-dot-item {
  background-color: white;
}
