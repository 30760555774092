.card-component {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  column-gap: 15px;
}

@media only screen and (max-width: 990px) {
  .card-component {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: block;
    margin: auto;
  }
}
