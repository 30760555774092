.startup-info-container {
  width: 100%;
  column-gap: 10px;
  .startup-name {
    text-align: left;
  }
  .position-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #504f4f;
    margin-bottom: 10px;
    text-align: left;
  }
  .position-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .position-buttons {
      background: #ffffff;
      border: 1px solid #a9a9a9;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #504f4f;
      padding: 10px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .selected-position {
      background: #f7f9f2;
      border: 1px solid #162642;
      width: 100%;
      padding: 10px 18px;
      border-radius: 8px;
    }
  }
  .startup-info-input {
    border: 1px solid #b1b3b6;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 20px;
    line-height: 0;
    // color: #939393;
    padding: 15px;
    &:focus {
      box-shadow: unset;
    }
  }
  .text-field {
    border: 1px solid rgba(117, 109, 109, 0.299);
    width: 100%;
    height: 50px;
    padding: 20px;
    border-radius: 5px;
  }
  @media (max-width: 767px) {
    row-gap: 10px;
    column-gap: 4px;
    flex-wrap: wrap;
    .position-container {
      .position-buttons {
        border-radius: 5px;
        width: 100%;
      }
    }
  }
}
.text-field {
  border: 1px solid #b1b3b6;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  font-size: 20px;
  line-height: 0;
  color: #939393;
}
