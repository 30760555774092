.modal-wrap-investor-history {
  position: fixed;
  height: calc(100vh - 180px);
  background: #f0f1f3;
  top: 72px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border: 1px solid #a0a7b2;
  box-shadow: 0px 4px 44px rgba(22, 38, 66, 0.1);
  border-radius: 5px;

  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - 280px);

    // @media only screen and (min-device-width: 428px) and (max-device-width: 428px) {
    //   height: calc(100vh - 320px); // iPhone 12/13 Pro Max
    // }

    // @media only screen and (min-device-width: 390px) and (max-device-width: 390px) {
    //   height: calc(100vh - 300px); // iPhone 12/13 Pro
    // }

    // @media only screen and (min-device-width: 375px) and (max-device-width: 375px) {
    //   height: calc(100vh - 290px); // iPhone SE/8
    // }

    // @media only screen and (max-device-width: 360px) {
    //   height: calc(100vh - 280px); // iPhone 8 and smaller
    // }
  }

  .modal-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    padding: 45px 0;
    height: 100%;
    min-width: 465px;
    margin: 0 auto;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background: #f0f1f3;
      bottom: -8px;
      right: 42px;
      transform: rotate(225deg);
      border-top: 1px solid #a0a7b2;
      border-left: 1px solid #a0a7b2;
      border-radius: 2px;
    }
    .invest-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      .buttonWrapperHistory {
        width: 100%;
        display: flex;
        align-items: center;
        .qa-btn-all {
          background-color: #7c8e54;
          border-radius: 5px;
          max-width: 72px;
          width: 100%;
          height: 42px;
          font-family: Roboto;
          font-size: 19px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          color: white;
          border: none;
        }
        .datePickerHistory {
          flex: 1;
          border-radius: 5px;
          border: 1px solid #3e6295;
          height: 42px;
        }
      }
      .investedAmtFilter {
        display: flex;
        width: 100%;
        .inputs-box {
          border-radius: 5px;
          border: 1px solid #3e6295;
          height: 42px !important;
        }
        input {
          margin-top: 5px;
        }
      }
    }
    button {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      padding: 11px 17px;
      background: #ffffff75;
      border: 1px solid #16264275;
      border-radius: 5px;
      color: #162642;
      height: 48px;
      &:focus {
        box-shadow: none;
      }

      &.active {
        background: #7c8e54;
        border: 1px solid #7c8e54;
        color: #ffffff;
        &:focus,
        &:disabled {
          box-shadow: none;
        }
      }
      &.apply {
        min-width: 135px;
        background: #162642;
        border: 1.5px solid #ffffff;
        border-radius: 5px;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        color: #ffffff;
      }
    }
    @media screen and (max-width: 767px) {
      &::before {
        right: 150px;
      }
    }
    @media screen and (max-width: 576px) {
      min-width: 371px;
      &::before {
        right: 100px;
      }
    }
    @media screen and (max-width: 375px) {
      min-width: unset;
      button {
        padding: 11px;
      }
      &::before {
        right: 45px;
      }
    }
  }
}
