div.failure-alert {
  color: #ff0000 !important;
  background-color: #ffd8d8 !important;
  border-radius: 5px;
  border: none;
  font-family: 'Roboto' !important;
  font-weight: 600;

  span.alert-exclamation {
    display: inline-flex;
    align-items: center;
    padding-right: 0.75rem;
  }

  span.alert-description {
    display: inline-flex;
    align-items: center;
  }
}
