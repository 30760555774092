.investor-modal-preview {
  min-height: 900px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: Roboto;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
}

.mobile-preview {
  height: 100%;
  width: 100%;
  background-color: white;

  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
}

.dashboard-container-preview {
  max-height: 780px;
  width: 948px !important;
  height: 100% !important;
  margin: 10px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 10px;
}

.close-container-preview {
  width: 948px;
  margin: auto;
}

.dashboard-container-preview-1 {
  margin: 30px auto 30px auto;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  max-width: fit-content;
  font-family: Roboto;
  font-size: 15px !important;
}

.link-preview {
  background-color: #162642;
  margin-top: 30px;
  float: right;
  height: 50px;
  width: 80px;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
}

.link-preview:hover {
  color: white;
  text-decoration: none;
  opacity: 0.8;
}

.bottom {
  margin-top: -21px !important;
  height: 85px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
}

.dashboard-preview-container-mobile {
  width: 100%;
  padding: 15px;
  font-family: Roboto;
}

h3,
h5,
p {
  font-family: Roboto;
}

button,
.complete-button {
  font-family: Roboto;
  outline: none !important;
}

.fade-enter div {
  opacity: 0;
}
.fade-enter-active div {
  opacity: 1;
}
.fade-exit div {
  opacity: 1;
}
.fade-exit-active div {
  opacity: 0;
}
.fade-enter-active div,
.fade-exit-active div {
  transition: opacity 500ms, transform 500ms;
}

.table-header,
.table-row-item {
  border: 0 !important;
}

.gold-colored {
  color: #edc432;
}

.purple-colored {
  color: #162642;
}

.mobile-row {
  display: flex;
  flex-direction: column;
}

.mobile-edit {
  color: #162642 !important;
  border: 1px solid #162642 !important;
  background-color: white !important;
  outline: none !important;
}

.mobile-save {
  color: white !important;
  border: 1px solid #162642 !important;
  background-color: #162642 !important;
  outline: none !important;
}

.mobile-pass {
  padding: 15px 50px !important;
  border-radius: 5px !important;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 0px !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.mobile-info {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;
  margin: 0 15px;
  text-align: left;
}

.mobile-info-no-margin {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;

  input::placeholder {
    font-family: Roboto;
    font-weight: 400px;
    font-size: 16px;
  }
}

.pt-3 {
  input::placeholder {
    font-size: 16px !important;
    color: #b5b5b5;
  }
}

.mobile-list {
  border: 1px solid #162642;
  border-radius: 30px;
  padding: 10px 20px;
  color: #162642;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 10px;
}

.mobile-founder {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -60px;
  right: -75px;
}

.sector-list-size,
.preview-team-position {
  font-size: 12px !important;
}

.dashboard-container-preview a,
.mobile-seemore,
.slide-deck-size {
  font-size: 19px !important;
}

//.mobile-preview-row-size {
//   margin-left: 10px !important;
//   width: 100% !important;
// }

.mobile-preview-header {
  font-size: 13px;
}

.mobile-row {
  font-family: Roboto;
}

.ml-25 h4,
.add-cofounder-text,
.dashboard-container-preview h1,
.container-preview-investor h1 {
  font-size: 25px !important;
}

.investor-profile-edit-title,
.investor-web-team h3,
.dashboard-container-preview h2,
.container-preview-investor h2,
.button-left,
.button-right {
  font-size: 23px !important;
}

.investor-preview-button,
.investor-edit-button,
.investor-profile-edit-text,
.investor-save-button,
.investor-web-team h5,
.remove-file-button,
.investor-edit-change-button,
.dashboard-container-preview h4,
.container-preview-investor h4,
.investor-card-section-title {
  font-size: 17px !important;
}

.investor-web-list,
.investor-web-team h6,
.remove-cofounder-button,
.dashboard-container-preview h5,
.container-preview-investor h5,
.container-preview-investor a,
.dashboard-container-preview a,
.mobile-seemore,
.link-preview,
.slide-deck-size {
  font-size: 15px !important;
}

.profile-pic-container {
  width: 150px !important;
}

.active-bottom-nav[disabled],
.inactive-bottom-nav[disabled] {
  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    border: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-container-preview-1 {
    font-size: 13px !important;
  }

  .ml-25 h4,
  .add-cofounder-text,
  .dashboard-container-preview h1,
  .container-preview-investor h1 {
    font-size: 20px !important;
  }

  .investor-profile-edit-title,
  .investor-web-team h3,
  .dashboard-container-preview h2,
  .container-preview-investor h2 {
    font-size: 18px !important;
  }

  .investor-preview-button,
  .investor-edit-button,
  .investor-profile-edit-text,
  .investor-save-button,
  .investor-web-team h5,
  .remove-file-button,
  .investor-edit-change-button,
  .dashboard-container-preview h4,
  .container-preview-investor h4,
  .investor-card-section-title {
    font-size: 14px !important;
  }

  .investor-web-list,
  .investor-web-team h6,
  .remove-cofounder-button,
  .dashboard-container-preview h5,
  .container-preview-investor h5,
  .container-preview-investor a,
  .dashboard-container-preview a,
  .mobile-seemore,
  .slide-deck-size {
    font-size: 12px !important;
  }

  .investor-profile-pic {
    height: 90px;
  }

  .ml-25 img {
    width: 63px !important;
  }

  .investor-linkedin-icon {
    width: 55px !important;
    height: 55px !important;
  }
}

@media only screen-dashboard and (max-width: 520px) {
  .bottom {
    height: 50px;
    border-radius: 10px;
    display: flex;
    position: fixed;
  }
}

@media only screen and (max-width: 480px) {
  .profile-pic-container {
    width: 125px !important;
  }

  .investor-img {
    height: 80px !important;
    width: 80px !important;
    margin-right: 25px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -10px !important;

  //   .ml-3 {
  //     font-size: 12px !important;
  //   }
  //   .font-20 {
  //     font-size: 20px !important;
  //   }

  //   .py-1 {
  //     font-size: 9px !important;
  //   }
  // }

  .mobile-info,
  .mobile-info-no-margin {
    font-size: 16px !important;
  }

  .mobile-row h5 {
    font-size: 18px !important;
  }

  .mobile-founder {
    padding-top: 3px !important;
    font-size: 14px !important;
    .pt-2 {
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 414px) {
  .investor-img {
    height: 70px !important;
    width: 70px !important;
    margin-right: 10px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -12px !important;

  //   .ml-3 {
  //     font-size: 11px !important;
  //   }
  //   .font-20 {
  //     font-size: 18px !important;
  //   }

  //   .py-1 {
  //     font-size: 8px !important;
  //   }
  // }

  .mobile-info,
  .mobile-info-no-margin {
    font-size: 15px !important;
  }

  .mobile-row h5 {
    font-size: 16px !important;
  }

  .mobile-founder {
    font-size: 13px !important;
    .pt-2 {
      font-size: 15px !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .profile-pic-container {
    width: 100px !important;
  }
  .attachment-size {
    .investor-profile-edit-text {
      font-size: 13px !important;
    }
  }

  .mobile-row {
    .remove-file-button {
      font-size: 16px !important;
    }
  }
  .investor-img {
    margin-right: 15px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -12px !important;

  //   .ml-3 {
  //     font-size: 10px !important;
  //   }
  //   .font-20 {
  //     font-size: 17px !important;
  //   }

  //   .py-1 {
  //     font-size: 7px !important;
  //   }
  // }

  .mobile-info {
    font-size: 14px !important;
  }

  .mobile-row h5 {
    font-size: 15px !important;
  }

  .mobile-founder {
    padding-top: 5px !important;
    font-size: 12px !important;
    .pt-2 {
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .investor-img {
    width: 65px !important;
    height: 65px !important;
  }

  .mobile-preview-right {
    .font-20 {
      font-size: 18px !important;
    }
    .ml-3 {
      font-size: 15px !important;
    }

    .py-1 {
      font-size: 9px !important;
    }
  }

  .mobile-preview-header {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 320px) {
  .mobile-row {
    .remove-file-button {
      font-size: 11px !important;
    }
  }

  .investor-img {
    width: 60px !important;
    height: 60px !important;
  }

  .mobile-preview-right {
    .font-20 {
      font-size: 17px !important;
    }
    .ml-3 {
      font-size: 14px !important;
    }

    .py-1 {
      font-size: 8px !important;
    }
  }

  .mobile-preview-header,
  .mobile-location-size {
    font-size: 10px !important;
  }
}
