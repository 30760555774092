.positions-container {
  background-color: black !important;
  width: 350px;
}

.interests2 {
  font-size: 26px;
}

.position-text {
  font-weight: 400;
  // margin-top: 30px;
  font-size: 16px;
  margin-bottom: 5px;
}
.startup-info-container {
  width: auto;
}
.info-container2 {
  border: 1px solid #b1b3b6;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #212529;
  padding: 20px;
  width: 100%;
}
.shortPitchField {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  min-height: 160px;
}

.info-container2::placeholder {
  font-size: 16px;
}

.startup-name-subhead {
  color: red;
  font-size: 16px;
}

.startup-input {
  border: 1px solid #b1b3b6 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 23px;
  color: #212529;
}
.text-field-input-wrap {
  .text-field {
    padding: 0 9px;
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
  .ant-input-number-focused {
    box-shadow: none;
  }
}
@media only screen and (max-height: 900px) and (max-width: 1440px) {
  .info-container2 {
    // padding: 2% !important;
    display: block !important;
    margin: auto !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 520px) {
  .startup-info-container {
    // width: 85%;
    .startup-name,
    .position-text,
    .info-container2,
    .info-container2::placeholder {
      font-size: 16px !important;
    }

    .position-buttons {
      width: fit-content !important;
      font-size: 15px !important;
      padding: 3px 17px !important;
      &:hover {
        transform: none !important;
      }
      &:active {
        transform: none !important;
      }
    }

    .selected-position {
      font-size: 15px !important;
      width: fit-content !important;
      padding: 3px 17px !important;
      transform: none !important;
    }

    .info-container2 {
      // padding: 2% !important;
      display: block !important;
      margin: auto !important;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .startup-info-container {
    .position-buttons,
    .selected-position {
      padding: 2px 14px !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .startup-info-container {
    .position-buttons,
    .selected-position {
      padding: 2px 13px !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .next-button {
    width: 180px !important;
  }

  .startup-info-container {
    .position-buttons,
    .selected-position {
      font-size: 14px !important;
      padding: 1px 10px !important;
    }
  }
}
