.footer-section {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(22, 38, 66, 0.1);
  height: 107px;
  position: unset;
  bottom: 0;
  width: 100%;
  margin-top: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    position: relative !important;
    margin-top: 0 !important;
  }
  .footer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    border-bottom: 4px solid transparent;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    &.active,
    &:hover {
      border-bottom: 4px solid #7c8e54;
      font-weight: 600;
    }
    a {
      font-size: 15px;
      line-height: 24px;
      color: #162642;
      margin-top: 9px;
      text-decoration: none;
      text-align: center;
    }
  }
}
