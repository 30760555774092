.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 38px;
  height: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  position: relative;
  transition: background-color 0.2s;
  margin-top: -22px;
}

.react-switch-label[disabled] {
  background: rgba(0, 0, 0, 0.1) !important;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 45px;
  transition: 0.1s;
  background: #fff;
  box-shadow: 0px 0px 0px 1px #dedfdf;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 20px;
}

@media only screen and (max-width: 500px) {
  .switch {
    transform: scale(1.2) !important;
  }
}
