.custom-dropdown{
    .notification-dummy{
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: #3e6295;
        font-weight: 500;
        font-size: 23px;
        line-height: 33px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
}