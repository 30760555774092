.investor-refer-page {
  margin: 0 auto;
}

.enjoy-experience {
  font-size: 44px;
  font-weight: 700;
  font-family: 'Roboto' !important;
  margin: 60px auto 40px;
  text-align: center;
}

.refer-us {
  font-size: 30px;
  font-family: Roboto;
  text-align: center;
  margin-top: -25px;
}

.sm-text-link {
  color: black;
}

.sm-text-link:hover {
  text-decoration: none;
}

.input-div button {
  height: 55px;
  border: 1px solid #edc432 !important;
  border-radius: 0 5px 5px 0;
  width: 150px;
  background-color: #edc432;
  color: white;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  vertical-align: center;
  line-height: 5px;
}

.refer-input {
  margin: 0px auto;
  padding-left: 20px;
  width: 700px;
  height: 55px;
  border: none;
  border: solid 1px #ccc;
  border-radius: 5px 0 0 5px;
  border: 1px solid #edc432 !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
  margin-left: 10px;
  font-size: 20px;
}

.feel-free {
  font-family: Roboto;
  text-align: center;
  margin-top: 35px;
  font-size: 20px;
}

.refer-logos {
  margin: 40px auto 5px auto;
  width: 350px;
  display: flex;
  justify-content: space-between;
}

.refer-logos div {
  border: 1px solid black;
  text-align: center;
}

.refer-logos img {
  height: 55px;
}

.logo-name {
  font-size: 17px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.3);
  font-family: Roboto;
}

@media only screen and (max-width: 768px) {
  .enjoy-experience {
    font-size: 35px !important;
    margin-top: 70px !important;
  }

  .refer-us {
    font-size: 30px !important;
  }

  .input-div-1,
  .feel-free,
  .logo-name {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 414px) {
  .enjoy-experience {
    font-size: 30px !important;
    margin-top: 25px !important;
  }

  .refer-us {
    font-size: 20px !important;
  }

  .feel-free {
    width: 60% !important;
    margin: auto !important;
  }

  .refer-logos {
    width: 300px !important;
  }

  .logo-name {
    width: 320px !important;
  }

  .input-div-1,
  .feel-free,
  .logo-name {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 360px) {
  .enjoy-experience {
    font-size: 25px !important;
    margin-top: 0px !important;
  }

  .refer-us {
    font-size: 18px !important;
  }

  .feel-free {
    width: 60% !important;
    margin: auto !important;
  }

  .refer-logos {
    width: 280px !important;
  }

  .logo-name {
    width: 295px !important;
  }

  .input-div-1,
  .feel-free,
  .logo-name {
    font-size: 15px !important;
  }
}
