.bottomBtns {
  .continueBtnOnboard {
    background: #162642;
    border-radius: 5px;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    padding: 12px 15px;
    border-color: transparent;
    .ant-spin-spinning {
      margin-left: 10px;
      .ant-spin-dot-spin {
        .ant-spin-dot-item {
          background-color: #fff;
        }
      }
    }
    &:focus {
      box-shadow: unset;
      background-color: #162642 !important;
    }
    &:active {
      background-color: #162642 !important;
    }
    &:hover {
      background: #162642;
      border-color: transparent;
    }
    &:disabled {
      opacity: 0.2;
      background: #162642;
      color: #ffffff;
    }

    .anticon {
      vertical-align: 0.05em;
      margin-left: 0.5rem;
    }
  }
  .backBtnOnboard {
    background-color: #ffffff !important;
    border: none;
    color: #162642 !important;
    font-weight: 400;

    &:hover {
      border: none !important;
      text-decoration: none;
      border-color: #ffffff;
    }

    &:focus {
      box-shadow: none;
    }

    .anticon {
      vertical-align: 0.125em;
      margin-right: 0.5rem;
    }
  }
}
// .pac-container {
//   display: none;
// }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #ffffff;
}
.ant-steps-item-active {
  margin-top: -25px;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #ffffff;
  border-color: #162642;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #162642;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #162642;
  opacity: 0.3;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  opacity: 0.3;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #162642;
}
.anticon {
  vertical-align: 0.025em;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #7c8e54;
}
.ant-progress-inner {
  background-color: #e9ece2;
}
.disable {
  // opacity: 0.3;
}
.heading-question {
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  margin-bottom: 0;
  color: #504f4f;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 32px;
  }
  .font-weight-bold {
    font-weight: 500;
  }
}
.selectAtleastSkillText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: rgba(93, 97, 102, 0.71);
  margin-top: 10px;
}
.skillsButton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  text-transform: capitalize;
  color: #162642;
}
.otherSkillText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;

  color: #504f4f;
}
.addSkillButton {
  position: absolute;
  right: 0px;
  background: #162642 !important;
  border-radius: 5px;
  color: #fff !important;
  width: 100px;
}
.inputSkillAdd:hover {
  border-color: #d9d9d9;
}
@media (max-width: 650px) {
  .calendly-popup {
    background: #fff;
  }
}
