.header-mobile {
  display: flex;
  padding: 0 24px;
  height: 72px;
  align-items: center;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0px 0px 12px rgba(22, 38, 66, 0.1);
  .ant-badge {
    img {
      min-width: 30px;
    }
  }
}

.tracking-section {
  .pop-header {
    display: flex;
    padding: 18px 24px;
    justify-content: space-between;
    align-items: center;
    background: #edeef1;
    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #425a85;
      margin-bottom: 0;
    }
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #162642;
    }
  }
  .pop-content {
    border-top: 0.7px solid #dadada;
    border-bottom: 0.7px solid #dadada;
    max-height: 330px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      background: #dadada;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background: #425a85;
      border-radius: 50px;
    }
    .line-hr {
      border-bottom: 0.7px solid #eee;
      padding: 8px 24px;
      .alignments {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }

      .scout-avatar-wrap {
        height: 34px;
        width: 34px;
        border-radius: 17px;
        background-color: #3e6295;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 23px;
          color: #ffffff;
          opacity: 0.6;
        }
      }
      h6 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        color: #343a40;
        margin-bottom: 0;
      }
      .notification-msg {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #162642;
        line-height: 21px;
        word-break: break-word;
        opacity: 0.8;
      }
      .timing {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: rgba(22, 38, 66, 0.52);
      }
    }
    .line-hr:last-child {
      border-bottom: none;
    }
  }
  .pop-footer {
    padding: 11px 0 13px 0;
    text-align: center;
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: #425a85;
      cursor: pointer;
    }
  }
}
