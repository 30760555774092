@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

h3,
h5,
p,
.card {
  font-family: Roboto;
}

.vertical-padding {
  padding: 80px 40px;
}

.incomplete-content {
  max-height: 545px;
  height: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  letter-spacing: 0px !important;
}

.dashboard-container h4 {
  width: 80%;
  margin: auto;
  font-weight: 400;
}

button,
.complete-button {
  font-family: Roboto;
  outline: none !important;
}

.complete-button {
  transition: all 0.2s ease-in-out;
  font-family: Roboto;
  &:hover {
    transform: scale(1.1);
  }
}

.pt-5 {
  font-size: 17px;
  font-family: Roboto;
}

.fade-enter div {
  opacity: 0;
}
.fade-enter-active div {
  opacity: 1;
}
.fade-exit div {
  opacity: 1;
}
.fade-exit-active div {
  opacity: 0;
}
.fade-enter-active div,
.fade-exit-active div {
  transition: opacity 500ms, transform 500ms;
}

.history-action-column {
  margin-right: 50px;
}

.history-container {
  height: 630px;
  width: 70% !important;
  margin: -10px auto 20px;
}

.history-card:hover {
  opacity: 0.8;
  cursor: pointer;
}

.startup-card-name-info-feedback-button {
  color: #162642 !important;
  border: 2px solid #162642 !important;
  background-color: transparent !important;
  font-size: 18px !important;
  margin-left: -10px;
}

.investor-card-name-info-feedback-button {
  color: #162642 !important;
  border: 1px solid #162642 !important;
  background-color: transparent !important;
}
.feedback-button-view-text {
  display: inline;
}
.feedback-button-view-icon {
  display: none;
  margin-left: 5px;
}

.inactive-team-num[disabled] {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
  color: #e0e0e0 !important;
}

.investor-card-photo {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.investor-dashboard-intro {
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 1000 !important;
}

.d-md-block {
  .dashboard-container-preview {
    max-height: 780px;
    width: 948px !important;
    height: 100% !important;
    margin: 10px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 10px;
  }
}

.dashboard-container {
  max-height: 780px;
  height: 100% !important;
  max-width: 1180px !important;
  width: 90% !important;
  margin: 0px auto 80px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 10px;
}

.investor-intro-container-0 {
  width: 460px !important;
  height: 300px !important;
  margin: 200px auto !important;
}
.investor-intro-title-0 {
  color: white;
  font-size: 45px;
  font-weight: bold;
  font-family: Roboto;
  line-height: 50px;
}
.investor-intro-content-0 {
  color: white;
  font-size: 25px;
  font-family: Roboto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.investor-intro-buttons-0 {
  width: 460px;
  display: flex;
  justify-content: space-between;
}
.investor-intro-button-left-0 {
  width: 220px;
  height: 60px;
  border-radius: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
}
.investor-intro-button-right-0 {
  width: 220px;
  height: 60px;
  border-radius: 5px;
  background-color: #162642;
  color: white;
  font-weight: 700;
  font-family: Roboto;
  border: none;
  font-size: 20px;
  font-weight: 700;
}

.investor-intro-button-m-top-0 {
  height: 60px;
  border-radius: 5px;
  background-color: #162642;
  color: white;
  font-weight: 700;
  font-family: Roboto;
  border: none;
  font-size: 20px;
  font-weight: 700;
}

.investor-intro-button-m-bottom-0 {
  height: 60px;
  border-radius: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
}

.investor-intro-background-1 {
  max-height: 780px;
  width: 948px !important;
  height: 100% !important;
  margin: 10px auto;
}

.investor-intro-container-1 {
  color: white;
  font-size: 25px;
  font-family: Roboto;
  width: 460px !important;
  height: 300px !important;
  margin: 150px auto 200px !important;
  position: relative !important;
  display: block !important;
  top: 20px !important;
  left: 0px !important;
}

.tip-3 {
  padding-top: 11px;
}

.investor-intro-tip-1 {
  color: #edc432;
  font-size: 20px;
  font-family: 'Roboto' !important;
  margin-bottom: 20px;
}

.mobile-preview-header {
  font-size: 13px;
}

.investor-intro-title-1 {
  color: white;
  font-size: 32px;
  font-weight: bold;
  font-family: Roboto;
  line-height: 50px;
}
.investor-intro-content-1 {
  color: white;
  font-size: 22px;
  font-family: 'Roboto' !important;
  margin-top: 20px;
  margin-bottom: 30px;
}

.investor-intro-buttons-1 {
  width: 460px;

  display: flex;
  justify-content: space-between;
}
.investor-intro-button-left-1 {
  width: 220px;
  height: 60px;
  border-radius: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  font-size: 20px;
  font-family: 'Roboto' !important;
  font-weight: 700;
}
.investor-intro-button-right-1 {
  width: 220px;
  height: 60px;
  border-radius: 5px;
  background-color: #162642;
  color: white;
  font-weight: 700;
  border: none;
  font-size: 20px;
  font-family: 'Roboto' !important;
  font-weight: 700;
}

.tip-1 {
  padding-top: 10px;
}

.invite-border {
  margin-left: 440px !important;
  margin-top: -53px !important;
  background-color: white;
  width: 535px !important;
  height: 110px !important;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 700;
  border-radius: 5px;
  border: white 5px solid;
}
.invite {
  color: white;
  background-color: #162642;
  width: 500px !important;
  height: 85px;
  text-align: center;
  line-height: 85px;
  font-weight: 700;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  border-radius: 0px 0px 10px 0px !important;
  margin-top: 6.5px !important;
  margin-left: 12px;
}

.first-visit {
  display: block;
  margin: -10px auto 0;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.invest-history {
  margin: -5px 47% -55px 53% !important;
  background-color: white;
  width: 120px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 5px;
  font-family: Roboto !important;
}

.invest-pic {
  top: -150px;
  right: 1%;
  position: relative !important;
  float: right !important;
  width: 112px;
  height: 80px;
  background-color: white;
  padding: 20px 35px;
  border-radius: 5px;
}

.investor-invite {
  padding-top: 37px !important;
}
.investor-intro-background-2 {
  height: 600px;
  width: 100%;
}

.screen-investor {
  letter-spacing: 0px !important;
}

.content {
  max-height: 545px;
  height: 100%;
  min-width: 400px;
  width: 100%;
  margin: auto;
  letter-spacing: 0px !important;
}

.bottom {
  height: 85px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
}

.button-left {
  position: relative !important;
  display: block !important;
  width: 590px;
  border: none;
  border-top: 1px solid #162642 !important;
  background-color: white;
  border-radius: 0px 0px 0px 10px;
  color: #162642;
  font-size: 25px;
  font-family: Roboto;
  &:hover {
    transform: scale(1) !important;
  }
}

.dashboard-button-left {
  position: relative !important;
  display: block !important;
  width: 590px;
  border: 1px solid #162642 !important;
  background-color: white;
  border-radius: 0px 0px 0px 10px;
  color: #162642;
  font-size: 25px;
  font-family: Roboto;
  &:hover {
    transform: scale(1) !important;
  }
}

.button-right {
  position: relative !important;
  display: block !important;
  width: 590px;
  background-color: #162642;
  color: white;
  font-size: 25px;
  border-radius: 0px 0px 10px 0px;
  border: 0;
  font-weight: 700;
  font-family: Roboto;
}

.dashboard-bottom-navigation {
  display: none;
  z-index: 5 !important;
  button {
    &:hover,
    &:active,
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
}

.card {
  font-family: 'Roboto' !important;
}

.profile-pic-container {
  width: 150px !important;
}

.history-button {
  background-color: transparent;
  margin-right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #b5b5b5 !important;
  font-family: 'Roboto' !important;
}

.active-history-button {
  background-color: #162642;
  color: white;
  font-weight: bold;
  outline: none !important;
  border: none !important;
}

.table-header {
  font-size: 18px;
  font-family: 'Roboto';
}

.table-header,
.table-row-item {
  border: 0 !important;
}

.investor-card {
  // border: 1px solid lightGrey;
  margin-bottom: 5px;
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden; /* hide standard table (collapsed) border */
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  box-shadow: 0 0 0 1px lightGrey !important; /* this draws the table border  */
  -webkit-box-shadow: 0 0 0 1px lightGrey !important;
  -moz-box-shadow: 0 0 0 1px lightGrey !important;
  -webkit-appearance: none !important;
}

.gold-colored {
  color: #edc432;
}

.purple-colored {
  color: #162642;
}

.investment-topics-1 {
  border: 1px solid #162642;
  color: #162642;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.pass-button:hover {
  opacity: 0.6;
}

.invite-button:hover {
  opacity: 0.9;
}

.history-button:hover {
  opacity: 0.8;
}

.mobile-row {
  display: flex;
  flex-direction: column;
}

.mobile-toggle-button {
  float: right;
  position: relative;
  z-index: 99;
  font-weight: 600;
  padding: 7px 20px;
  font-size: 16px;
  font-family: Roboto;
  border-radius: 5px;
  outline: none !important;
}

.mobile-edit {
  color: #162642 !important;
  border: 1px solid #162642 !important;
  background-color: white !important;
  outline: none !important;
}

.mobile-save {
  color: white !important;
  border: 1px solid #162642 !important;
  background-color: #162642 !important;
  outline: none !important;
}

.mobile-pass {
  padding: 15px 50px !important;
  border-radius: 5px !important;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 0px !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.mobile-header {
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
}

.mobile-info {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;
  margin: 0 15px;
  text-align: left;
}

.mobile-info-no-margin {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;

  input::placeholder {
    font-family: Roboto;
    font-weight: 400px;
    font-size: 16px;
  }
}

.pt-3 {
  input::placeholder {
    font-size: 16px !important;
    color: #b5b5b5;
  }
}

input::placeholder {
  font-family: Roboto;
  font-weight: 400px !important;
  color: #b5b5b5 !important;
}

.mobile-list {
  border: 1px solid #162642;
  border-radius: 30px;
  padding: 10px 20px;
  color: #162642;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 10px;
}

.mobile-founder {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -60px;
  right: -75px;
}

.investor-profile-pic {
  border-radius: 50%;
  height: 120px !important;
  width: 120px !important;
}

.investor-web-list {
  border: 1px solid #162642;
  border-radius: 30px;
  padding: 10px 20px;
  color: #162642;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 5px;
}

.investor-linkedin-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 65px !important;
  height: 65px !important;
}

.investor-save-button {
  background-color: #162642 !important;
  border: 1px solid #162642 !important;
  outline: none !important;
  color: white !important;
  padding: 8px 20px !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
  margin-top: 10px;
}

.remove-cofounder-button {
  border: 1px solid #162642 !important;
}

.investor-edit-change-button {
  color: #162642 !important;
  text-decoration: none !important;
  border: 2px solid #162642 !important;
  padding: 15px 25px !important;
  border-radius: 10px !important;
  font-family: Roboto;
  font-weight: 700 !important;
  position: relative !important;
}

.dashboard-container-preview a,
.mobile-seemore,
.slide-deck-size {
  font-size: 19px !important;
}

.preview-team-card {
  border: 2px solid lightgrey !important;
  border-radius: 5px;
  text-align: center;
  width: 161px !important;
  height: 153px;
}

.preview-member-pic {
  width: 60px !important;
  height: 60px !important;
}

.preview-linkedin {
  width: 25px;
  position: absolute;
  margin-left: 15px !important;
}

.preview-team-position {
  font-size: 15px;
  position: relative;
  top: -10px;
}

//.mobile-preview-row-size {
//   margin-left: 10px !important;
//   width: 100% !important;
// }

.mobile-preview-header {
  font-size: 13px;
}

.investor-investment-icon {
  width: 45px !important;
  height: 45px !important;
}

.container-preview-investor {
  height: 535px;
  width: 900px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.mobile-row {
  font-family: Roboto;
}

.ipad-positions {
  font-size: 16px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.plusicon {
  height: 30px !important;
  margin-top: -25px !important;
}

.ml-25 h4,
.add-cofounder-text,
.dashboard-container-preview h1,
.container-preview-investor h1 {
  font-size: 20px !important;
}

.investor-profile-edit-title,
.investor-web-team h3,
.dashboard-container-preview h2,
.container-preview-investor h2,
.button-left,
.button-right {
  font-size: 22px !important;
}

.investor-preview-button,
.investor-edit-button,
.investor-profile-edit-text,
.investor-save-button,
.investor-web-team h5,
.remove-file-button,
.investor-edit-change-button,
.dashboard-container-preview h4,
.container-preview-investor h4,
.investor-card-section-title {
  font-size: 16px !important;
}

.investor-web-list,
.investor-web-team h6,
.remove-cofounder-button,
.dashboard-container-preview h5,
.container-preview-investor h5,
.container-preview-investor a,
.dashboard-container-preview a,
.mobile-seemore,
.link-preview,
.slide-deck-size {
  font-size: 15px !important;
}

.ml-25 img {
  width: 80px !important;
}

.investment-card-icon {
  width: 65px !important;
  height: 65px !important;
}

.close-container-preview {
  width: 948px !important;
}

.bottom {
  margin-top: -21px !important;
}

.bottom-connected-view {
  margin-top: -21px !important;
  text-align: center;
  font-weight: 700;
  font-family: 'Roboto' !important;
  font-size: 20px;
  background-color: #162642;
  color: white;
  width: 100%;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top-connected-view {
  margin: auto;
  margin-top: 0px !important;
  position: relative;
  top: 5px;
  z-index: 5;
  text-align: center;
  font-weight: 700;
  font-family: 'Roboto' !important;
  font-size: 20px;
  background-color: #162642;
  color: white;
  width: 100%;
  padding: 25px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.font-weight-status {
  font-weight: 700 !important;
}

.mobile-name-location {
  position: relative;
  top: -90px;
  margin-bottom: -90px;
  left: 100px;
}

.mobile-prof-pic {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-left: -15px;
  border-radius: 50%;
}

.mobile-website {
  position: relative;
  text-align: right;
  top: -66px;
  right: 0px;
  z-index: 5;
  margin-top: -25px;
}

.mobile-card-sectors {
  min-width: 250px;
  position: relative;
  top: 5px;
  left: 108px;
  span {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .ml-25 h4,
  .dashboard-container-preview h1,
  .container-preview-investor h1 {
    font-size: 22px !important;
  }

  .investor-web-team h3,
  .dashboard-container-preview h2,
  .container-preview-investor h2,
  .button-left,
  .button-right {
    font-size: 20px !important;
  }

  .dashboard-container-preview h4,
  .container-preview-investor h4,
  .investor-card-section-title {
    font-size: 15px !important;
  }

  .investor-web-team h6,
  .remove-cofounder-button,
  .dashboard-container-preview h5,
  .container-preview-investor h5,
  .container-preview-investor a,
  .dashboard-container-preview a,
  .mobile-seemore,
  .slide-deck-size,
  .link-preview {
    font-size: 13px !important;
  }

  .ml-25 img {
    width: 70px !important;
  }

  .investor-linkedin-icon,
  .investment-card-icon {
    width: 60px !important;
    height: 60px !important;
  }

  .sector-list-size,
  .preview-team-position {
    font-size: 13px !important;
  }

  .bottom {
    margin-top: 25px !important;
  }

  .bottom-investor {
    margin-top: 0px !important;
  }

  .dashboard-container-preview .p-5,
  .container-preview-investor .p-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .dashboard-container-preview .py-5,
  .container-preview-investor .py-5 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-top: 25px !important;
  }

  .investor-investment-icon {
    width: 40px !important;
    height: 40px !important;
  }
  .close-container-preview {
    width: 760px !important;
  }
  .d-md-block {
    .dashboard-container-preview {
      width: 760px !important;
    }
    .close-container-preview {
      width: 760px !important;
    }

    .preview-team-card {
      width: 127px !important;
      height: 137px !important;
    }

    .preview-member-pic {
      width: 45px !important;
      height: 45px !important;
    }

    .preview-linkedin {
      width: 15px;
      position: absolute;
      margin-left: 5px;
    }

    .preview-left {
      margin-left: -30px !important;
      .inner-left {
        padding-left: 100px !important;
      }
    }

    .preview-right {
      margin-left: 10px !important;
      padding-right: 40px !important;
    }

    .slide-deck-size {
      padding: 7px 20px;
    }
  }
  .investor-intro-background-1 {
    width: 760px !important;
  }

  .invite-border {
    margin-left: 360px !important;
    margin-top: -115px !important;
    width: 415px !important;
  }
  .invite {
    width: 385px !important;
    height: 85px;
  }

  .investor-invite {
    padding-top: 130px !important;
  }

  .history-card,
  .investor-card {
    h4 {
      font-size: 16px !important;
    }

    .font-23 {
      font-size: 16px !important;
    }
    .font-20 {
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .invite-border {
    margin-top: -81px !important;
  }
  .investor-invite {
    padding-top: 97px !important;
  }
}

@media screen and (max-width: 920px) {
  .history-container {
    width: 90% !important;
  }
  .investor-card-name-info-name {
    font-size: 17px;
    margin-bottom: 0;
  }
  .investor-card-name-info .font-weight-600 {
    font-size: 19px !important;
  }

  .investor-card-name-info .font-weight-400 {
    font-size: 15px !important;
  }
  .startup-card-name-info-feedback-button {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 767px) {
  button {
    &:hover,
    &:active,
    &:focus {
      outline: none !important;
    }
  }
  .history-card,
  .investor-card {
    h4 {
      font-size: 14px !important;
    }
    .gold-colored {
      color: black !important;
    }
    .font-weight-700 {
      font-weight: 400 !important;
    }
    .font-23 {
      font-size: 14px !important;
    }
    .font-20 {
      font-size: 12px !important;
    }
  }

  .ml-25 h4,
  .add-cofounder-text,
  .dashboard-container-preview h1,
  .container-preview-investor h1 {
    font-size: 20px !important;
  }

  .investor-profile-edit-title,
  .investor-web-team h3,
  .dashboard-container-preview h2,
  .container-preview-investor h2 {
    font-size: 18px !important;
  }

  .investor-preview-button,
  .investor-edit-button,
  .investor-profile-edit-text,
  .investor-save-button,
  .investor-web-team h5,
  .remove-file-button,
  .investor-edit-change-button,
  .dashboard-container-preview h4,
  .container-preview-investor h4,
  .investor-card-section-title {
    font-size: 14px !important;
  }

  .investor-web-list,
  .investor-web-team h6,
  .remove-cofounder-button,
  .dashboard-container-preview h5,
  .container-preview-investor h5,
  .container-preview-investor a,
  .dashboard-container-preview a,
  .mobile-seemore,
  .slide-deck-size {
    font-size: 12px !important;
  }

  .ml-25 img {
    width: 63px !important;
  }

  .investor-linkedin-icon,
  .investment-card-icon {
    width: 55px !important;
    height: 55px !important;
  }

  .table-header {
    font-size: 16px !important;
  }

  .invest-pic {
    width: 60px !important;
    height: 60px !important;
    line-height: 60px !important;
    border-radius: 50% !important;

    padding: 0 !important;
    right: -10px !important;
    top: -10px !important;
    position: relative !important;
    img {
      margin-left: 10px !important;
      margin-bottom: 3px !important;
    }
  }

  .tip-1 {
    padding-top: 20px !important;
  }

  .tip-2 {
    padding-top: 20px !important;
  }

  .tip-3 {
    padding-top: 1px !important;
    margin-left: 1px !important;
  }

  .investor-intro-container-1 {
    width: 100% !important;
  }
  .investor-intro-background-1 {
    margin: 20px auto 0 !important;
    width: 90% !important;
  }

  .investor-intro-background-2 {
    margin: auto !important;
    padding-top: 20px !important;
    width: 90% !important;
  }

  .dashboard-bottom-navigation {
    display: block;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0 {
    max-width: 450px !important;
    width: 100% !important;
  }

  .investor-intro-title-1 {
    font-size: 30px !important;
  }

  .investor-intro-content-1 {
    font-size: 23px !important;
  }

  .mobile-invite {
    background-color: #162642;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    color: white;
    font-weight: 900;
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 80px;
    text-align: center;
    position: fixed;
    bottom: 60px;
    right: 20%;
  }

  .mobile-history {
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 110px;
    color: black;
    font-weight: 900;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    position: fixed;
    bottom: 5px;
    right: 16%;
  }
}

@media only screen and (max-width: 600px) {
  .add-cofounder-border {
    width: 120% !important;
    margin-left: -10% !important;
  }
}

@media only screen-dashboard and (max-width: 520px) {
  .dashboard-container {
    height: 100%;
    width: 95%;
  }

  .content-left {
    margin: 0 auto;
    width: 100%;
    height: 230px;
  }
  .startup-logo {
    float: left;
    height: 130px;
  }
  .startup-name {
    float: right;
    margin-top: -150px;
    color: #162642;
    font-size: 35px;
    font-weight: bold;
    text-align: left;
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizeSpeed;
    letter-spacing: 0px !important;
  }

  .bottom {
    height: 50px;
    border-radius: 10px;
    display: flex;
    position: fixed;
  }

  .button-left {
    width: 180px;
  }

  .button-right {
    width: 180px;
  }
}

@media screen and (max-width: 520px) {
  .feedback-button-view-icon {
    display: inline;
  }
  .feedback-button-view-text {
    display: none;
  }
  .investor-card-name-info-feedback-button {
    font-size: 14px !important;
  }
  .history-action-column {
    display: none;
  }
  .investor-card-photo {
    height: 40px !important;
    width: 40px !important;
  }

  .investor-card-name-info-name {
    font-size: 17px;
    margin-bottom: 0;
  }
  .investor-card-name-info-location {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .investor-card {
    border: 1px solid lightGrey;
    margin-bottom: 5px;
    border-radius: 10px !important;
    box-shadow: none !important; /* this draws the table border  */
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-appearance: none !important;
  }

  .content {
    min-width: 200px;
  }

  .vertical-padding {
    padding: 80px 10px;
  }
  .mobile-card-sectors span {
    font-size: 10px !important;
  }

  .table-row-item {
    padding: 15px 10px !important;
  }

  .history-table-padding {
    left: -2px;
  }

  .font-weight-status {
    font-weight: 600 !important;
  }
  .history-card,
  .investor-card {
    h4 {
      font-size: 13px !important;
    }
    .gold-colored {
      color: black !important;
    }
    .font-weight-600 {
      font-weight: 400 !important;
    }
    .font-23 {
      font-size: 13px !important;
    }
    .font-20 {
      font-size: 13px !important;
    }
  }

  .startup-card-name-info-feedback-button {
    margin-left: -12px !important;
    margin-right: -10px !important;
    border: 0px !important;
  }

  .invest-pic {
    top: -120px !important;
  }

  .tip-3 {
    margin-top: 130px !important;
    margin-left: 0px !important;
  }

  .investor-intro-background-2 {
    padding-top: 0px !important;
    width: 90% !important;
  }

  .profile-pic-container {
    width: 125px !important;
  }

  .investor-img {
    height: 80px !important;
    width: 80px !important;
    margin-right: 25px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -10px !important;

  //   .ml-3 {
  //     font-size: 12px !important;
  //   }
  //   .font-20 {
  //     font-size: 20px !important;
  //   }

  //   .py-1 {
  //     font-size: 9px !important;
  //   }
  // }

  .mobile-info,
  .mobile-info-no-margin {
    font-size: 16px !important;
  }

  .mobile-row h5,
  .mobile-location {
    font-size: 18px !important;
  }

  .investor-intro-container-0 {
    margin: 120px auto 0 !important;
    width: 90% !important;
  }

  .investor-intro-background-1 {
    margin: -40px auto 0 !important;
  }

  .investor-intro-title-0 {
    font-size: 40px !important;
  }

  .investor-intro-content-0 {
    font-size: 22px !important;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0,
  .investor-intro-content-1 {
    font-size: 18px !important;
  }

  .investor-intro-title-1 {
    font-size: 28px !important;
  }

  .mobile-history {
    right: 15%;
  }
  .mobile-invite {
    right: 19%;
  }
}

@media only screen and (max-width: 414px) {
  .mobile-website {
    top: -90px;
    right: -24px;
  }

  .mobile-card-sectors span {
    font-size: 10px !important;
  }
  .investor-img {
    height: 70px !important;
    width: 70px !important;
    margin-right: 10px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -12px !important;

  //   .ml-3 {
  //     font-size: 11px !important;
  //   }
  //   .font-20 {
  //     font-size: 18px !important;
  //   }

  //   .py-1 {
  //     font-size: 8px !important;
  //   }
  // }

  .mobile-info,
  .mobile-info-no-margin {
    font-size: 15px !important;
  }

  .mobile-row h5,
  .mobile-location {
    font-size: 16px !important;
  }

  .investor-intro-title-0 {
    font-size: 36px !important;
  }

  .investor-intro-content-0 {
    font-size: 19px !important;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0,
  .investor-intro-content-1 {
    font-size: 17px !important;
  }

  .investor-intro-title-1 {
    font-size: 26px !important;
  }
  .mobile-history {
    right: 14%;
  }
  .mobile-invite {
    right: 18%;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-name-location {
    left: 85px;
  }

  .mobile-card-sectors {
    left: 93px;
    span {
      font-size: 9px !important;
    }
  }

  .profile-pic-container {
    width: 100px !important;
  }

  .attachment-size {
    .investor-profile-edit-text {
      font-size: 13px !important;
    }
  }

  .mobile-row {
    .remove-file-button {
      font-size: 16px !important;
    }
  }

  .table-header {
    font-size: 14px !important;
  }

  .investor-img {
    margin-right: 15px !important;
  }

  //.mobile-preview-row-size {
  //   margin-top: -12px !important;

  //   .ml-3 {
  //     font-size: 10px !important;
  //   }
  //   .font-20 {
  //     font-size: 17px !important;
  //   }

  //   .py-1 {
  //     font-size: 7px !important;
  //   }
  // }

  .mobile-info {
    font-size: 14px !important;
  }

  .mobile-row h5,
  .mobile-location {
    font-size: 15px !important;
  }

  .investor-intro-title-0 {
    font-size: 32px !important;
  }

  .investor-intro-content-0 {
    font-size: 16px !important;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0,
  .investor-intro-content-1 {
    font-size: 16px !important;
  }

  .investor-intro-title-1 {
    font-size: 25px !important;
  }

  .mobile-history {
    right: 13%;
  }
  .mobile-invite {
    right: 17%;
  }
}

@media only screen and (max-width: 360px) {
  .mobile-name-location {
    font-size: 14px !important;
    left: 65px;
    top: -78px;
  }

  .mobile-prof-pic {
    height: 80px;
    width: 80px;
  }

  .mobile-website {
    top: -70px;
    right: -26px;
  }

  .mobile-card-sectors {
    left: 73px;
    top: 15px;
    span {
      font-size: 8px !important;
    }
  }

  .investor-card-photo {
    height: 30px !important;
    width: 30px !important;
  }

  .history-card,
  .investor-card {
    h4 {
      font-size: 11px !important;
    }
    .font-23 {
      font-size: 11px !important;
    }
    .font-20 {
      font-size: 11px !important;
    }
  }

  .investor-img {
    width: 65px !important;
    height: 65px !important;
  }

  .mobile-preview-right {
    .font-20 {
      font-size: 18px !important;
    }
    .ml-3 {
      font-size: 15px !important;
    }

    .py-1 {
      font-size: 9px !important;
    }
  }

  .mobile-preview-header {
    font-size: 12px !important;
  }

  .investor-intro-title-0 {
    font-size: 30px !important;
  }

  .investor-intro-content-0 {
    font-size: 15px !important;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0,
  .investor-intro-content-1 {
    font-size: 15px !important;
  }

  .investor-intro-title-1 {
    font-size: 23px !important;
  }
}

@media only screen and (max-width: 320px) {
  .invest-pic {
    top: -55px !important;
  }

  .tip-3 {
    margin-top: 68px !important;
  }

  .mobile-name-location {
    font-size: 12px !important;
    left: 45px;
    top: -65px;
  }

  .mobile-prof-pic {
    height: 60px;
    width: 60px;
  }

  .mobile-website {
    top: -57px;
    right: -30px;
  }

  .mobile-card-sectors {
    left: 53px;
    top: 25px;
    span {
      font-size: 6px !important;
    }
  }

  .history-card,
  .investor-card {
    h4 {
      font-size: 10px !important;
    }
    .font-23 {
      font-size: 10px !important;
    }
    .font-20 {
      font-size: 10px !important;
    }
  }
  .attachment-size {
    .investor-profile-edit-text {
      font-size: 10px !important;
    }
  }

  .replace-sizing {
    font-size: 9px !important;
  }

  .mobile-row {
    .remove-file-button {
      font-size: 14px !important;
    }
  }

  .add-cofounder-border {
    width: 125% !important;
    margin-left: -12.5% !important;
  }

  .mobile-preview-header,
  .mobile-location-size {
    font-size: 10px !important;
  }

  .history-button {
    font-size: 12px !important;
  }

  .table-header {
    font-size: 12px !important;
  }

  .number-padding {
    padding: 4px 20px !important;
  }

  .investor-img {
    width: 60px !important;
    height: 60px !important;
  }

  //.mobile-preview-row-size {
  //   width: 160px !important;
  // }

  // .mobile-preview-right {
  //   .font-20 {
  //     font-size: 17px !important;
  //   }
  //   .ml-3 {
  //     font-size: 14px !important;
  //   }

  //   .py-1 {
  //     font-size: 8px !important;
  //   }
  // }

  .mobile-preview-header,
  .mobile-location-size {
    font-size: 10px !important;
  }

  .investor-intro-tip-1 {
    font-size: 12px !important;
  }

  .investor-intro-container-0 {
    margin: 100px auto 0 !important;
  }

  .investor-intro-background-1 {
    margin: -100px auto 0 !important;
  }

  .investor-intro-title-0 {
    font-size: 28px !important;
  }

  .investor-intro-content-0 {
    font-size: 13px !important;
  }

  .investor-intro-button-m-top-0,
  .investor-intro-button-m-bottom-0,
  .investor-intro-content-1 {
    font-size: 13px !important;
  }

  .investor-intro-title-1 {
    font-size: 22px !important;
  }
  .mobile-history {
    right: 12%;
  }

  .mobile-invite {
    right: 16%;
  }
}
