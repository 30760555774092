.onboarding-header-main-wrap {
  .hire-btn-group {
    column-gap: 15px;
    @media (max-width: 767px) {
      column-gap: 5px;
    }
    @media (max-width: 375px) {
      margin-left: 5px;
    }
    .hire-btn {
      background: #162642;
      border-radius: 38px;
      font-weight: 400;
      font-size: 18px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: #ffffff;
      padding: 12px 20px;
      border: unset;
      @media (max-width: 767px) {
        font-size: xx-small;
        padding: 6px 10px;
      }
    }
    .logout-btn {
      border: 1px solid #162642;
      border-radius: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 15px;
      color: #162642;
      padding: 12px 20px;
      background: transparent;
      @media (max-width: 767px) {
        font-size: xx-small;
        padding: 6px 10px;
      }
    }
  }
}

button.ant-btn.ant-btn-submit.inviteBtn {
  background-color: #7c8e54;
  color: white;
  border: #7c8e54;
  border-top-right-radius: 6px;
  padding: 5px 21px;
  border-bottom-right-radius: 6px;
}

.inviteTextBox {
  .ant-input-group {
    display: flex;
    max-width: 480px;
    margin: 0 auto;
    .ant-input {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}

.inviteSpin i.ant-spin-dot-item {
  background-color: white;
}

.thankyou-wrapper {
  background: #ffffff;
  height: calc(100vh - 91px);
  .display-number {
    background: #e1e5d7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #162642 !important;
    padding: 6px;
    margin: 0 5px;
  }
  .invite-email-wrapper {
    border: 1px solid #425a85;
    box-sizing: border-box;
    border-radius: 8px;
    @media (max-width: 991px) {
      width: 100% !important;
    }
    .invite-email {
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: black;
      border-radius: 8px;
      height: 45px;
      &:focus {
        box-shadow: unset;
      }
    }
    .input-group-text {
      border-radius: 0 8px 8px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .invite-text {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #162642;
    margin-bottom: 20px;
  }
  .input-group {
    width: 70%;
  }
  .excited-text {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #504f4f;
  }
  .thankyou-text {
    font-weight: 400;
    font-size: 30px;
    line-height: 48px;
    color: #162642;
  }
  .middleSet {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #504f4f;
    padding: 40px 45px !important;
    .tracking-details {
      font-weight: 400;
      font-size: 19px;
      line-height: 29px;
      text-align: left;
      color: #504f4f;

      .tracking-monthly-update {
        font-weight: 500;
        color: #162642;
      }
    }
    .tracking-badge {
      background: #e1e5d7;
      border-radius: 5px;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #162642;
      padding: 8px 15px;
    }
    .tracking-name {
      font-weight: 700;
      color: #5d6166;
    }

    .tracking-text {
      font-weight: 500;
      font-size: 22px;
      line-height: 48px;
      text-align: center;
      color: #7c8e54;
    }

    @media (max-width: 767px) {
      padding: 20px !important;
      font-size: 18px;
    }
  }
  .new-application-btn {
    color: white;
    border-radius: 5px;
    background: var(--MainColor, #162642);
  }
  .tracking-started {
    width: 295px;
    height: 45px;
    background: #162642;
    border-radius: 5px;
    color: #ffff;
    font-size: inherit;
    @media (max-width: 767px) {
      width: 100%;
    }
    &:hover {
      border-color: transparent;
    }
  }
}

.homepageReturnBtn {
  background: #162642 !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 11px 93px !important;
  border-color: transparent !important;
}

.thankyouHeaderLogo {
  background: url('/static/media/LogoExitFund.3d12dd21.svg');
  height: 45px;
  width: 230px;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    width: 50px;
  }
}

@media (max-width: 767px) {
  .rejectedAppBtn {
    width: 100%;
    padding: 10px 20px;
    height: 54px;
    font-size: 20px;
    border-radius: 6px;
  }
}

@media (min-width: 768px) {
  .rejectedAppBtn {
    width: 57%;
    padding: 10px 20px;
    height: 54px;
    font-size: 19px;
    border-radius: 6px;
  }
}

.approvedText {
  font-size: 19px !important;
}
