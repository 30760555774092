$font-default: 'Roboto';

.button-container {
  margin: 0px 0px 10px 0px;
  text-align: center;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#syndicate-input {
  width: 100% !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
}

.input-form {
  padding: 10px;
  font-family: $font-default !important;
}

.syndicate-list {
  margin-left: 0px !important;
  padding-left: 0px !important;
  font-family: $font-default !important;
}

.syndicate-group {
  border: 1px solid rgba(117, 109, 109, 0.299);
  margin: 0 auto;
  margin-top: 20px;
  padding-left: 5px;
  padding-top: 8px;
  padding-bottom: 15px;
  border-radius: 5px;
  width: 450px;
  display: flex;
  list-style-type: none;
  font-family: $font-default !important;
}

.fa-times-thin:before {
  content: '\00d7';
  font-size: 21px;
  font-family: $font-default !important;
}

.fa-search {
  content: '\00d7';
  font-size: 20px;
  font-family: $font-default !important;
}

.angelgroup-text {
  font-size: 18px;
  padding-top: 7px;
  padding-left: 10px;
  font-family: $font-default !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.cssCircle {
  position: absolute;
  margin-top: 10px;
  margin-left: 405px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;

  width: 20px;
  height: 20px;
  padding: 0px;

  background: #162642;
  border: 1px solid #162642;
  color: white;
  font-weight: bolder;
  text-align: center;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
  transition: color 0.2s linear;

  font: 24px 'Arial, sans-serif';
}

.cssCircle:hover {
  background: #162642;
  cursor: pointer;
}

.minusSign {
  line-height: 0;
  padding-bottom: 3px;
}

.remove {
  position: relative;
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 700 !important;
  cursor: pointer;
  color: #b5b5b5 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  // margin-left: 10px !important;
  font-family: $font-default !important;
}

form.example2 .done {
  float: right;
  border-radius: 5px !important;
  // margin-right: 14px;
  width: 15%;
  font-size: 16px !important;
  padding: 8px !important;
  border-radius: 3px solid #162642 !important;
  color: white;
  background-color: #162642;
  font-weight: 700;
  font-family: $font-default !important;

  &:hover {
    background-color: #162642 !important;
    color: white !important;
  }
}

.done[disabled] {
  background-color: #f3f3f3 !important;
  border: 2px solid #f3f3f3 !important;
  border-radius: 5px;
  color: #dededf !important;
  font-weight: 400;
}

.text-field {
  border: 1px solid rgba(117, 109, 109, 0.299);
  width: 100%;
  height: 50px;
  padding: 15px;
  border-radius: 5px;
}

.form-container {
  animation: fadeInOut 1s linear forwards;
  border: 1px solid rgba(117, 109, 109, 0.299);
  margin-left: 40%;
  margin-right: 50%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
  max-width: 450px;
  width: 90%;
  position: relative;
}

.form-container2 {
  animation: fadeInOut 0.5s linear forwards;
  border: 1px solid rgba(117, 109, 109, 0.299);
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
}

form.example2 input[type='text'] {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  font-size: 17px;
  border: 1px solid #dededf !important;
  float: left;
  width: 425px !important;
  background: #fff;

  border-radius: 5px !important;
  margin-left: 10px;

  padding: 10px;
  font-size: 17px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-right: none;
  float: left;
  width: 80%;
  background: #fff;
  margin-top: 10px;
}

form.example2::after {
  content: '';
  clear: both;
  display: table;
}

.eyeIconGlass {
  position: absolute;
  margin-top: 30px;
  left: 87.5%;
}

.XIcon {
  position: absolute;
  margin-top: 28px;
  left: 86%;
  width: 32px;
}

form.example2 ::placeholder {
  color: #dededf;
  font-size: 16px;
}

form.example2 button {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  float: left;
  width: 20%;

  background: white;
  color: #edc432;
  font-size: 17px;
  border: 1px solid #dededf;
  border-left: none;
  cursor: pointer;

  border-radius: 0 5px 5px 0;

  float: left;
  width: 20%;
  padding: 10px;
  background: white;
  color: #edc432;
  font-size: 17px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: white !important;
    cursor: pointer;
  }
}

.btn-primary2 {
  background-color: #162642 !important;
  outline: none !important;
  border: none !important;
  margin: 0 auto !important;
  height: 70px;
  font-weight: 700 !important;
  font-size: 19px !important;
  // padding-bottom: 45px !important;
  // padding-top: 15px !important;
  max-width: 450px !important;
  width: 90% !important;
  animation: fadeOutIn 1s linear forwards;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px !important;
}

.btn-primary-hide {
  background-color: #f3f3f3 !important;
  outline: none !important;
  border: none !important;
  border-radius: 5px;
  color: #dededf !important;
  margin: 0 auto !important;
  height: 70px;
  font-size: 19px !important;
  // padding-bottom: 45px !important;
  // padding-top: 15px !important;
  max-width: 450px !important;
  width: 90% !important;
  animation: fadeOutIn 1s linear forwards;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px !important;
}

.container-statement {
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}

.step-statement {
  color: #162642;
  font-weight: 500;
  font-size: 20px;
  animation: fadeInOut 1s linear forwards;
}

.step-statement-active {
  color: #162642;
  font-weight: 700;
  font-size: 22px;
  animation: fadeInOut 1s linear forwards;
  text-decoration: underline;
}

.step-statement2 {
  color: #162642;
  font-weight: 500;
  font-size: 17px;
  animation: fadeInOut 1s linear forwards;
}

@media only screen and (max-width: 420px) {
  .form-container {
    // margin-left: 10%;
    // margin-right: 10%;
  }
  .syndicate-group {
    width: 296px !important;
  }
}

@media only screen and (max-width: 767px) {
  .eyeIconGlass {
    position: absolute;
    margin-top: 20px;
    left: 83%;
    width: 18px;
  }

  .XIcon {
    position: absolute;
    margin-top: 20px;
    left: 83%;
    width: 22px;
  }

  .cssCircle {
    right: 15%;
  }
  .btn-primary2 {
    height: 65px !important;
  }

  .btn-primary-hide {
    height: 65px !important;
  }

  .step-statement {
    font-size: 14px !important;
  }
  .step-statement-active {
    font-size: 16px !important;
    text-decoration: underline;
  }

  .step-statement2 {
    font-size: 14px !important;
  }

  // .form-container {
  //   width: 300px !important;
  // }

  .syndicate-group {
    padding: 5px 0px !important;
    width: 80%;
  }

  form.example2 input[type='text'] {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-left: 16px !important;
    font-size: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    float: left;
    width: 275px !important;
    background: #fff;

    border-radius: 5px !important;
    margin-left: 10px;
  }

  form.example2 button {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    float: left;
    width: 20%;

    background: white;
    color: #edc432;
    font-size: 17px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left: none;
    cursor: pointer;

    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: white !important;
      cursor: pointer;
    }
  }

  .fa-search {
    content: '\00d7';
    font-size: 17px;
  }

  .remove {
    font-size: 14px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-weight: 700 !important;
    cursor: pointer;
    color: #b8b8b8 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    // margin-left: 10px !important;
  }

  form.example2 .done {
    float: right;
    border-radius: 5px !important;
    // margin-right: 14px;
    width: 20%;
    font-size: 12px !important;
    padding: 5px !important;
    border-radius: 3px solid #162642 !important;
    color: white;
    background-color: #162642;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

  .done[disabled] {
    background-color: #f3f3f3 !important;
    border: 2px solid #f3f3f3 !important;
    border-radius: 5px;
    color: #dededf !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

  .text-field {
    border: 1px solid rgba(117, 109, 109, 0.299);
    width: 100%;
    height: 50px;
    padding: 20px;
    border-radius: 5px;
  }
  .engineersTextField {
    padding: 5px;
    height: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .btn-primary2 {
    font-weight: 500 !important;
  }
}

@media only screen and (max-width: 320px) {
  form.example2 input[type='text'] {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 520px) {
  .btn-primary2 {
    font-size: 16px !important;
    height: 60px !important;
  }

  form.example4 .remove {
    margin-left: 2% !important;
  }

  .btn-primary-hide {
    // padding: 5px 10px !important;
    font-size: 16px !important;
    height: 60px !important;
  }

  .syndicate-step {
    padding-left: 9px;
  }

  .angelgroup-text {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 14px !important;
  }

  .cssCircle {
    margin-top: 5px !important;
    margin-left: 300px !important;
  }

  form.example2 ::placeholder {
    font-size: 16px !important;
  }
}
