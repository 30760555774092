.scout-steps-main-wrap {
  background: #fff;
  .scout-steps {
    flex-direction: row !important;
    .ant-steps-item-title::after {
      display: none;
    }

    .firstStep {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0 !important;
      padding-left: 65px !important;
      @media (max-width: 767px) {
        padding-left: 0 !important;
        justify-content: flex-start;
      }
    }
    .ant-steps-item-finish {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
        .ant-steps-item-icon {
          background: rgba(124, 142, 84, 0.23);
          border-radius: 62px;
          border: unset;
          opacity: 1;
          @media (max-width: 375px) {
            margin-right: 5px;
          }
          .ant-steps-icon {
            color: #7c8e54;
          }
        }
        .ant-steps-item-content {
          @media (max-width: 767px) {
            min-height: 0;
          }
          .ant-steps-item-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #828589;
            opacity: 1;
            text-transform: uppercase;
            @media (max-width: 375px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .ant-steps-item-process {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
        .ant-steps-item-icon {
          @media (max-width: 375px) {
            margin-right: 5px;
          }
        }
        .ant-steps-item-content {
          @media (max-width: 767px) {
            min-height: 0;
          }
          .ant-steps-item-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #162642;
            @media (max-width: 375px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
        .ant-steps-item-icon {
          border: 1px solid #e1e1e2;
          background: transparent;
          @media (max-width: 375px) {
            margin-right: 5px;
          }
        }
        .ant-steps-item-content {
          @media (max-width: 767px) {
            min-height: 0;
          }
          .ant-steps-item-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #828589 !important;
            @media (max-width: 375px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .done-step {
      .ant-steps-item-icon {
        border: none !important;
        height: 32px !important;
        width: 32px !important;
        .ant-steps-icon {
          height: 100%;
          width: 100%;
          top: -1px !important;
          line-height: 1 !important;
          svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .backBtnOnboard {
    background-color: #ffffff !important;
    border: none;
    color: #162642 !important;
    font-weight: 400;

    &:hover {
      border: none !important;
      text-decoration: none;
      border-color: #ffffff;
    }

    &:focus {
      box-shadow: none;
    }

    .anticon {
      vertical-align: 0.125em;
      margin-right: 0.5rem;
    }
  }
}
