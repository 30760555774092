.closely-relate-option-step-wrap {
  .heading-question {
    font-weight: 400;
    font-size: 29px;
    line-height: 39px;
    text-align: center;
    color: #504f4f;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 33px;
    }
    .heading-bold {
      font-weight: 500;
    }
  }
  .IdentifyAsAnyOne {
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    .relate-wrapper {
      column-gap: 25px;
      row-gap: 20px;
      flex-wrap: wrap;
      .relate-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 8px 35px;
        column-gap: 15px;
        cursor: pointer;
        @media (max-width: 767px) {
          width: 100%;
          justify-content: flex-start;
        }
        svg {
          width: 60px;
          height: 60px;
          color: #425a85;
        }
        .relate-btn-text {
          font-weight: 400;
          font-size: 24px;
          text-transform: capitalize;
          color: #3e4c63;
        }
      }
      .selected {
        background: #f7f9f2;
        border: 2px solid #7c8e54 !important;
        border-radius: 5px;
        svg {
          color: #7c8e54;
        }
        .relate-btn-text {
          color: #504f4f;
        }
      }
    }
  }
  .bottomBtns {
    .continueBtnOnboard {
      background: #162642;
      border-radius: 5px;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      padding: 10px 15px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon-loading {
        margin-right: 8px;
        padding-right: 0;
      }
    }
  }
}
