@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Trending-column {
  margin-bottom: 30px;
}
.trendingHeading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #7c8e54;
}

.card-component-sectors {
  animation: fadeInOut 1s linear forwards;
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center;
}

.input-sector-container {
  animation: fadeInOut 1s linear forwards;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .card-component-sectors2 {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #162642;
  }
}

.transparent-selection-layer {
  background-color: #162642;
  opacity: 0.3;
  z-index: 100;
  position: absolute;
  width: 190px;
  z-index: 100;
}

.milestones-container {
  width: 1000px !important;
  text-align: center;
  margin: 10px auto;
  column-gap: 22px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.milestone-card-background {
  background-color: transparent;
}

.trending-card {
  margin-bottom: 40px !important;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: none !important;
  border: 1px solid #dededf;
}

.progress-card {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #dededf;
}

.title-trending-card {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
  color: #162642;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.selected-sector {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #162642;
}

.trending-card {
  margin-bottom: 40px !important;
  cursor: pointer;
  border-radius: 5px;
}
.innerTrendingCard {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;

  img {
    margin-right: 10px;
    width: 26px;
    height: auto;
  }
}

.title-sectors-container {
  border-radius: 5px;
}

.trending-card2 {
  width: 150px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .title-sectors-container,
  .trending-card,
  .trending-card2 {
    display: none;
  }
}

.trending-title {
  font-size: 18px;
  font-weight: 700;
}

.border-sector {
  min-height: 55px;
  border: 1px solid #b6b5b1;
  border-radius: 5px;
  padding: 7px 30px !important;
  column-gap: 10px;
  cursor: pointer;
  font-size: 14px;
  svg {
    max-width: 100%;
    height: auto;
    color: #3e6295 !important;
  }
}

.border-sector-selected {
  background: #fafbf8 !important;
  border: 2px solid #7c8e54 !important;
  box-sizing: border-box;
  border-radius: 5px;
  column-gap: 10px;
  svg {
    color: #7c8e54 !important;
  }
  svg g path {
    fill: #7c8e54 !important;
  }
}

.sectorIcon {
  width: 25px;
  height: auto;
  border: none;
  margin-right: 10px;
  margin-top: -2px;
}

@media only screen and (max-width: 1024px) {
  .card-component-sectors,
  .trending-title {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .Trending-column {
    margin-bottom: 0px !important;
  }
  .title-sectors-container,
  .title-trending-card,
  .trending-card,
  .trending-card2 {
    display: block !important;
  }
  .input-sector-container .card-component-sectors2 {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .border-sector {
      // background-color: red;
      min-height: 70px;
    }
    input {
      width: 100%;
    }
    label {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
