.short-bio-step-wrap {
  .heading-question {
    font-weight: 400;
    font-size: 26px;
    line-height: 51px;
    text-align: center;
    color: #504f4f;
    margin-bottom: 26px;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 35px;
      margin-bottom: 25px;
    }
    .bold {
      font-weight: 500;
    }
  }
  .heading-question-small {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #504f4f;
    text-align: center;
    margin-bottom: 13px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 25px;
    }
  }
  .short-bio-textarea {
    .ant-input {
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.8rem;
      padding: 12px;
      color: #162642;
      &::placeholder {
        color: rgba(62, 76, 99, 0.65);
      }
      &:focus {
        box-shadow: unset;
      }
      @media (max-width: 767px) {
        font-size: 19px;
        line-height: 28px;
      }
    }
    &::placeholder {
      font-size: 1rem;
      line-height: 1.8rem;
    }
  }
}
