@import '../../Hiring/LocationCardHiring/LocationCard.scss';
@import '../../Startup/SignUpStartup/SignUpStartup.scss';
@import '../../Investor/SyndicatePage/SyndicatePage.scss';
$font-default: 'Roboto', sans-serif;
.startup-info-wrapper {
  ::placeholder {
    font-size: 16px;
  }
  // background: #fff;
  .heading-question {
    font-weight: 400;
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 0;
    color: #504f4f;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
    }
    .font-weight-bold {
      font-weight: 500;
    }
  }
  .heading-question-small {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #9e9e9e;
    text-align: center;
  }
  .startup-info-container {
    margin: 0 auto;
    display: grid;
    text-align: left;
    font-family: 'Roboto', sans-serif;

    .startup-name {
      float: left;
      font-weight: 400 !important;
      margin-top: 35px;
      font-size: 20px !important;
      line-height: 26px;
      margin-bottom: 10px !important;
      font-family: $font-default, sans-serif !important;
      color: #504f4f;
      &:first-child {
        margin-top: 20px;
      }
    }

    input.date_not_exists.text-field {
      color: #959595 !important;
    }

    .text-field {
      font-size: 16px;
      padding: 15px !important;
      font-family: 'Roboto', sans-serif;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #212529;
      display: flex;
      &:focus {
        box-shadow: unset;
      }
      &::placeholder {
        color: blue;
      }
    }

    .text-field::placeholder {
      font-size: 16px;
      color: red;
    }

    .info-container2 {
      width: 100% !important;
      resize: none;
      border: 1px solid rgba(117, 109, 109, 0.299);
      border-radius: 5px;
      margin-left: 0px;
      // width: 450px;
      width: 100%;
      font-size: 16px;
      font-family: $font-default, sans-serif !important;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 20px;
      // padding-bottom: 80px;
    }
  }

  .bottomBtns {
    margin-top: 50px;
    .continueBtnOnboard {
      background: #162642;
      border-radius: 5px;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      padding: 10px 15px;
    }
  }

  .startup-step {
    text-align: center;
    padding-top: 10px;
    margin: auto;
    width: 800px;
    font-size: 18px;
    font-weight: 600;
    font-family: $font-default, sans-serif !important;
    margin-bottom: 30px;
  }
  .pull-left {
    float: left;
    background-color: white !important;
    color: #162642 !important;
    border-color: #162642 !important ;
    padding: 10px 20px !important;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }

  .pull-right {
    float: right;
    background-color: #162642 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-color: #162642 !important ;
    font-weight: 700 !important;
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    .anticon {
      vertical-align: 0;
    }
  }

  .pull-right[disabled] {
    background-color: #f3f3f3 !important;
    border: 2px solid #f3f3f3 !important;
    border-radius: 5px;
    color: #dededf !important;
  }

  .steps-mobile {
    display: none;
  }

  .buttons-container {
    display: none;
  }

  .group-buttons {
    display: none;
  }

  @media only screen and (max-width: 900px) and (min-width: 502px) {
    .startup-step {
      text-align: center;
      padding-top: 10px;
      margin: auto;
      width: 500px;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    .padding-100 {
      padding-bottom: 100px;
    }

    .info-container2 {
      width: 89vw !important;
      font-size: 17px !important;
    }

    .group-buttons {
      display: block;
    }

    .buttons-container {
      display: block;
      z-index: 5;
      margin: 0 auto;
      width: 100%;
      position: fixed;
      left: 0 !important;
      bottom: 0;
      border: none;
      padding-top: 65px;
      box-shadow: 0px 1;
    }

    .startup-step {
      display: none;
    }

    .steps-mobile {
      display: block;
      text-align: left;
      margin-left: 26px !important;
      font-size: 15px;
      font-family: 'Roboto' !important;
    }

    .padding {
      padding: 10px;
    }

    .footer-button {
      position: absolute;
      bottom: 0;
    }
  }
}
.startup-steps {
  flex-direction: row !important;

  .ant-steps-item-title::after {
    display: none;
  }
  .firstStep {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    @media (max-width: 991px) {
      padding-left: 0 !important;
      justify-content: flex-start;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      .ant-steps-item-icon {
        background: rgba(124, 142, 84, 0.23);
        border-radius: 62px;
        border: unset;
        opacity: 1;
        @media (max-width: 375px) {
          margin-right: 5px;
        }
        .ant-steps-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          // color: #7c8e54;
          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
            font-size: 14px;
          }
        }
      }
      .ant-steps-item-content {
        @media (max-width: 767px) {
          min-height: 0;
        }
        .ant-steps-item-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #828589;
          opacity: 1;
          text-transform: uppercase;
          @media (max-width: 375px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      .ant-steps-item-icon {
        border: 1px solid #162642;
        background: transparent;
        @media (max-width: 375px) {
          margin-right: 5px;
        }
        .ant-steps-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #162642 !important;
          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
            font-size: 14px;
          }
        }
      }
      .ant-steps-item-content {
        @media (max-width: 767px) {
          min-height: 0;
        }
        .ant-steps-item-title {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          color: #162642;
          @media (max-width: 375px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      .ant-steps-item-icon {
        border: 1px solid #e1e1e2;
        background: transparent;
        @media (max-width: 375px) {
          margin-right: 5px;
        }
        .ant-steps-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          // color: #7c8e54;
          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
            font-size: 14px;
          }
        }
      }
      .ant-steps-item-content {
        @media (max-width: 767px) {
          min-height: 0;
        }
        .ant-steps-item-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #828589 !important;
          @media (max-width: 375px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .doneStep {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        border: unset;
        background: rgba(124, 142, 84, 0.23);
      }
    }
  }
}
