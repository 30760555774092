.cofounders-carousel {
  .carousel-inner {
    height: 100% !important;
    min-height: 150px !important;
  }

  .carousel-indicators {
    display: none !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: -20px;
    opacity: 1 !important;
    font-weight: bold;
    border-radius: '50%';
  }

  .carousel-control-prev {
    left: -85px;
  }
  .carousel-control-next {
    right: -55px;
  }
  .carousel-control-next:before {
    content: '' !important;
  }
  .carousel-control-prev:before {
    content: '' !important;
  }
  .carousel-control-next:after {
    content: url('../../assets/images/rightarrow.png') !important;
  }

  .carousel-control-prev:after {
    content: url('../../assets/images/leftarrow.png') !important;
  }
}
.str-arrows > .carousel-control-prev,
.str-arrows > .carousel-control-next {
  display: none !important;
}

.missing {
  font-size: 15px;
}

.startup-card-invite-button {
  width: 100%;
  background-color: #162642 !important;
  color: white;
  border-radius: 0px !important;
  border-bottom-right-radius: 10px !important;
}

.startup-card-pass-button {
  width: 100%;
  background-color: transparent !important;
  color: #162642 !important;
  border-color: #162642 !important;
  border-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
}

.pass-button-text,
.invite-button-text {
  font-size: 1.5rem;
}

.dashboard-container-preview {
  max-height: 780px;
  width: 948px !important;
  height: 100% !important;
  margin: 10px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 10px;
}

.seemore-button:hover,
.slide-deck-button:hover {
  cursor: pointer;
}

.bottom {
  margin-top: -21px !important;
  height: 85px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
}

.button-left {
  width: 590px;
  border: none;
  border-top: 1px solid #162642 !important;
  background-color: white;
  border-radius: 0px 0px 0px 10px;
  color: #162642;
  font-size: 25px;
  font-family: Roboto;
  &:hover {
    transform: scale(1) !important;
  }
}

.button-right {
  width: 590px;
  background-color: #162642;
  color: white;
  font-size: 25px;
  border-radius: 0px 0px 10px 0px;
  font-family: Roboto;
  border: 0;
  font-weight: 700;
  &:hover {
    transform: scale(1) !important;
  }
}

h3,
h5,
p {
  font-family: Roboto;
}

.dashboard-container h4 {
  width: 80%;
  margin: auto;
  font-weight: 400;
}

button,
.complete-button {
  font-family: Roboto;
  outline: none !important;
}

.fade-enter div {
  opacity: 0;
}
.fade-enter-active div {
  opacity: 1;
}
.fade-exit div {
  opacity: 1;
}
.fade-exit-active div {
  opacity: 0;
}
.fade-enter-active div,
.fade-exit-active div {
  transition: opacity 500ms, transform 500ms;
}

.table-header,
.table-row-item {
  border: 0 !important;
}

.gold-colored {
  color: #edc432;
}

.purple-colored {
  color: #162642;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.pt-3 {
  input::placeholder {
    font-size: 16px !important;
    color: #b5b5b5;
  }
}

.investor-profile-pic {
  border-radius: 50%;
  height: 120px !important;
  width: 120px !important;
}

.sector-list-size,
.preview-team-position {
  font-size: 12px !important;
}

.dashboard-container-preview a,
.mobile-seemore,
.slide-deck-size {
  font-size: 19px !important;
}

.preview-team-card {
  border: 2px solid lightgrey !important;
  border-radius: 5px;
  text-align: center;
  width: 161px !important;
  height: 153px;
}

.preview-member-pic {
  width: 60px !important;
  height: 60px !important;
}

.preview-linkedin {
  width: 25px;
  position: absolute;
  margin-left: 15px !important;
}

.preview-team-position {
  position: relative;
  top: -10px;
}

.ml-25 h4,
.add-cofounder-text,
.dashboard-container-preview h1,
.container-preview-investor h1 {
  font-size: 25px !important;
}

.investor-profile-edit-title,
.investor-web-team h3,
.dashboard-container-preview h2,
.container-preview-investor h2,
.button-left,
.button-right {
  font-size: 23px !important;
}

.investor-preview-button,
.investor-edit-button,
.investor-profile-edit-text,
.investor-save-button,
.investor-web-team h5,
.remove-file-button,
.investor-edit-change-button,
.dashboard-container-preview h4,
.container-preview-investor h4,
.investor-card-section-title {
  font-size: 17px !important;
}

.investor-web-list,
.investor-web-team h6,
.remove-cofounder-button,
.dashboard-container-preview h5,
.container-preview-investor h5,
.container-preview-investor a,
.dashboard-container-preview a,
.mobile-seemore,
.link-preview,
.slide-deck-size {
  font-size: 15px !important;
}

.slide-deck-size {
  padding: 8px 30px;
}

.progress-list-size {
  font-size: 13px !important;
}

.preview-left {
  margin-left: 5px !important;
  .inner-left {
    padding-left: 17px !important;
  }
}

.preview-right {
  margin-left: -25px !important;
  padding-right: 48px !important;
}

.slide-div {
  width: 225px !important;
}

@media only screen and (max-width: 1000px) {
  .d-md-block {
    .dashboard-container-preview {
      width: 760px !important;
    }
    .close-container-preview {
      width: 760px !important;
    }

    .preview-team-card {
      width: 127px !important;
      height: 137px !important;
    }

    .preview-member-pic {
      width: 45px !important;
      height: 45px !important;
    }

    .preview-linkedin {
      width: 15px;
      position: absolute;
      margin-left: 5px;
    }

    .preview-left {
      margin-left: -30px !important;
      .inner-left {
        padding-left: 100px !important;
      }
    }

    .preview-right {
      margin-left: 10px !important;
      padding-right: 40px !important;
    }

    .sector-list-size {
      font-size: 9px !important;
    }

    .progress-list-size {
      font-size: 10px !important;
    }

    .slide-deck-size {
      padding: 7px 20px;
    }

    .slide-div {
      margin-left: 20px !important;
      width: 260px !important;
    }

    .missing {
      font-size: 14px !important;
    }
  }
}

@media only screen-dashboard and (max-width: 520px) {
  .bottom {
    height: 50px;
    border-radius: 10px;
    display: flex;
    position: fixed;
  }
}

@media only screen and (max-width: 320px) {
  .mobile-preview-header,
  .mobile-location-size {
    font-size: 12px !important;
  }
}
