$font-default: 'Roboto', sans-serif;

.mobile-line {
  margin-top: -9%;
}

.question {
  text-align: center;
  margin-bottom: 10px;
  animation: fadeInOut 1s linear forwards;
  font-family: $font-default !important;
}

.startup-step {
  text-align: center;
  padding-top: 10px;
  margin: auto;
  // width: 800px;
  font-size: 18px;
  font-weight: 600;
  font-family: $font-default, sans-serif !important;
  margin-bottom: 10px;
}

.pull-left {
  float: left;
  background-color: white !important;
  color: #162642 !important;
  border-color: #162642 !important ;
  padding: 10px 20px !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  .anticon {
    vertical-align: 0.125em;
    margin-right: 0.5rem;
  }
}

.pull-right {
  float: right;
  background-color: #162642 !important;
  color: white !important;
  padding: 10px 20px !important;
  border-color: #162642 !important ;
  font-weight: 700 !important;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  .anticon {
    vertical-align: 0.05em;
    margin-left: 0.5rem;
  }
}

.pull-right[disabled] {
  background-color: #f3f3f3 !important;
  border: 2px solid #f3f3f3 !important;
  border-radius: 5px;
  color: #dededf !important;
}

.steps-mobile {
  display: none;
}

.example ::placeholder {
  color: #b5b5b5;
  font-family: $font-default !important;
  font-size: 18px;
}

.location-XIcon {
  font-family: $font-default !important;
  width: 24px;
  height: 24px;
}

.location-input input[type='text'] {
  padding: 28px;
  font-size: 18px;
  border-right: none;
  width: 90%;
  border-radius: 5px 5px 5px 5px;
  color: #333333;
  font-family: 'Roboto', sans-serif;
}

.location-eyeIcon {
  z-index: -1;
}
.citiesWrapper {
  text-align: center;
  margin-bottom: 32px;

  .citiesHeading {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    border-bottom: 1px solid #7c8e54;
    padding-bottom: 8px;
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
}

.locationSet {
  position: relative;
  width: 100% !important;
  margin-bottom: 1.5rem;
  .location-container {
    position: relative;
    .locationAddIcon {
      position: absolute;
      width: fit-content;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    .ant-input-prefix {
      margin-right: 13px;
      margin-left: 5px;
    }
    .ant-input {
      // border: solid 1px blue;
      line-height: 2;
    }
    .ant-input-affix-wrapper {
      border-radius: 8px;
    }
    .locationAddIcon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 18px;
      }
    }
  }
  .button-location-container {
    border: none;
    position: absolute;
    top: 0px;
    height: 100%;
    right: 55px;
    padding: 11px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}
.bottomBtns {
  .continueBtnOnboard {
    background: #162642;
    border-radius: 5px;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    padding: 10px 15px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon-loading {
      margin-right: 8px;
      padding-right: 0 !important;
    }
    &:disabled {
      opacity: 0.2;
      color: #ffffff;
    }
    .anticon-arrow-right {
      svg {
        color: #fff;
      }
    }
  }
}

.button-location-container {
  padding: 14px;
  position: relative;
  // margin-top: -50px;
  // right: 10%;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
  border: none;
  border-left: none !important;

  &:focus {
    border: none;
    border-left: none !important;
  }
  &:hover {
    transform: none !important;
  }
}

.example button {
  font-family: $font-default !important;
  float: left;
  width: 20%;
  background: white;
  color: #edc432;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0 !important;
  padding: 14.7px !important;

  &:hover {
    background-color: white !important;
    cursor: pointer;
  }
}

.step-question {
  font-family: $font-default !important;
  animation: 1s fadeInOut linear forwards;
  display: block;
  width: 100vw;
  margin: 0 auto 30px;
  font-size: 20px;
  color: #504f4f;
}

.example {
  width: 100% !important;
}

.location-XIcon {
  height: 21px;
  width: 21px;
}

.location-eyeIcon {
  height: 19px;
  width: 19px;
}

.button-location-container {
  // right: -233px;
}

.push {
  height: 70px;
}

.buttons-container {
  background-color: white;
  z-index: 5;
  margin: 0 auto;
  width: 400px;
  position: fixed;
  left: 35%;
  bottom: 0;
  border: none;
  padding-top: 55px;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px 11px 8px -10px #ccc, 0px -11px 8px -10px #ccc;
}

.group-buttons {
  display: none;
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  z-index: 6;
  position: fixed;
  bottom: 0;
  margin-bottom: 7px;
  font-family: $font-default !important;

  .back-button {
    margin: 0 5px;
    background-color: white;
    border-color: #162642;
    color: #162642;
    font-family: $font-default !important;

    &:hover {
      background-color: white;
      color: #162642;
    }
  }

  .next-button {
    margin: -14px 5px 0 !important;
    width: 200px;
    background-color: #162642;
    border-color: #162642;
    color: white;
    font-weight: 700;
    font-family: $font-default !important;
    &:hover {
      background-color: #162642;
    }
    &:active {
      background-color: #162642;
    }
  }

  .btn-primary:disabled {
    color: #162642;
    background-color: white;
    border-color: #162642;
  }
}

@media only screen and (max-width: 580px) {
  .example {
    max-width: 73% !important;
  }

  .example input[type='text'] {
    font-size: 16px !important;
  }

  .example ::placeholder {
    font-size: 16px !important;
  }

  .example button {
    padding: 7.7px !important;
  }

  .button-location-container {
    // right: -145.5px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 502px) {
  .startup-step {
    text-align: center;
    padding-top: 10px;
    margin: auto;
    width: 500px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .startup-step {
    display: none;
  }

  .steps-mobile {
    display: block;
    text-align: left;
    margin-left: 26px !important;
    font-size: 15px;
    font-family: 'Roboto' !important;
  }

  .buttons-container {
    display: block;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    left: 0 !important;
    bottom: 0;
    border: none;
    padding-top: 65px;
    box-shadow: 0px 1;
  }

  .group-buttons {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .button-location-container {
    // right: -28% !important;
  }
}

@media only screen and (max-width: 420px) {
  .buttons-container {
    left: 0% !important;
  }
}
