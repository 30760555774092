.ant-progress-bg {
    background-color: #7c8e54 !important;
}

.inviteSpinner i.ant-spin-dot-item {
    background-color: white;
}

.errMsg{
    font-size: medium;
}