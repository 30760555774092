$font-default: 'Roboto', sans-serif;

.sector-milestone {
  animation: 1s fadeInOut linear forwards;
  border: 1px solid #e1e1e2;
  border-radius: 5px;
  margin: 0 auto;
  width: 340px;
  margin-bottom: 8px !important;
  padding: 2px;
  .checkbox {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: block;
    label {
      font-family: $font-default !important;
      position: relative;
      min-height: 34px;
      display: block;
      padding-left: 40px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;

      span {
        font-family: $font-default !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px !important;
        font-family: $font-default, sans-serif !important;
        font-weight: 500;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 4px;
        height: 28px;
        transition: transform 0.28s ease;
        border-radius: 50%;
        border: 1px solid #dededf;
        width: 28px;
      }
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 10px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.5s;
        position: absolute;
        top: 11px;
        left: 10px;
      }
    }
    input[type='checkbox'] {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
      margin-left: -20px;
      &:checked ~ label {
        &:before {
          border: 1px solid #162642;
          background-color: #162642;
        }
        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }
}
.question {
  margin-bottom: 3rem;
}
.innerMilestoneCard {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  position: relative;

  img {
    margin-right: 10px;
    width: 36px;
    height: auto;
  }
}
.identify-wrapper {
  .heading-question {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #504f4f;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 32px;
    }
    .font-weight-bold {
      font-weight: 500;
    }
  }
  .heading-question-small {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #5d6166;
    margin-top: 15px;
    text-align: center;
  }
}
@media only screen and (min-width: 1025px) {
  .sector-milestone {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .sector-milestone {
    width: 90vw !important;
  }
}

.identifyTags {
  .progress-card {
    margin: 10px 0;
    min-width: 250px;
  }
}
