.milestones-container2 {
  column-gap: 18px;
  .innerMilestoneCard {
    img {
      width: auto;
      height: 30px;
    }
  }
}

.ant-tooltip-placement-right {
  .ant-tooltip-content {
    width: 300px;
    .ant-tooltip-inner {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #504f4f;
      background-color: #f3f5f8;
      box-shadow: none;
    }
  }
}
.innerMilestoneCard {
  .anticon {
    vertical-align: 0.025em;
    text-align: right;
    right: 5px;
    position: absolute;
    top: 5px;
    font-size: larger;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaecef;

    svg {
      color: #000;
      border-radius: 50%;
      padding: 3px;
    }
  }
}
