$btn-bg-color: #f2f2ff;
$prime-color: #162642;
$white-color: #fff;
$hover-color: #ffd500;
$secondary-color: #5f51f4b3;
$font-size-large: 64px;
$faded-color: #b4b4b4;

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  // background-color: #f8f8f9  !important;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

a {
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  text-decoration: none;

  &.type-link {
    text-align: center;
    color: rgba(0, 0, 0, 0.2);

    &:hover {
      color: rgba(0, 0, 0, 0.2);
      text-decoration: none;
      opacity: 0.6;
    }

    &:active {
      color: rgba(0, 0, 0, 0.2);
      text-decoration: none;
    }
  }
}

.email-af-link:hover {
  text-decoration: none;
}

.mention {
  height: 250px;
}

.email-af-link:hover {
  opacity: 0.6;
}
.sm-footer-link:hover {
  opacity: 0.6;
}

.logo {
  &:hoveer {
    text-decoration: none;
  }
  .logo-tag {
    font-weight: bold;
    margin-top: 4%;
    &:hoveer {
      text-decoration: none;
    }
  }
}

h3 {
  &:hoveer {
    text-decoration: none;
  }
}

.mainbtn {
  display: flex;
  align-items: center;
  margin: 4em 0;
}

.mainbtn button {
  background-color: white;
  border: 1px solid #f2f2ff;
  padding: 1em;
  border-radius: 5px;
  color: $prime-color;
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px !important;
  font-size: 15px;
}

.mainbtn .active {
  font-weight: 600;
}

.head_heading {
  color: $prime-color !important;
  font-family: 'Roboto', sans-serif !important;
  width: 100% !important;
  font-weight: bold !important;
  font-size: $font-size-large;
  text-align: left;
  margin-bottom: 1em;
}

.head_subtitle {
  font-size: 23px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1em;
}

.bg-container h4 {
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
}

.req-btn {
  padding: 0.7em 1em;
  border: none;
  border-radius: 7px;
  background-color: $prime-color;
  color: $white-color;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 2px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 21px;
  outline: none;
  cursor: pointer;
  font-weight: 700 !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 6px 0px #edc432;
    transform: scale(1.01);
    color: $white-color;
    text-decoration: none;
  }
}

.btn-size-2 {
  width: 300px;
}

.icon-size {
  height: 30px;
  width: 50px;
  padding: 0 20px 0 0;
}

.peopleSay .main-content img {
  width: 100px;
}

.peopleSay > div:nth-child(1) {
  background: url('../../assets/images/layer.png') no-repeat;
  background-size: 100% 100%;
  height: 274px;
  position: relative;
}

.peopleSay > div:nth-child(2) {
  background: url('../../assets/images/Group\ 2815.png') no-repeat;
  background-size: contain;
  height: 274px;
  position: relative;
  margin: -18px 0 -16px -12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.peopleSay > div:nth-child(3) {
  background: url('../../assets/images/Group\ 2817.png') no-repeat;
  background-size: 100% 100%;
  height: 274px;
  position: relative;
}

.background-off-purple {
  background-color: #fcfcff;
}

.joinBeta {
  width: 100%;
  background-color: #fcfcff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.joinBeta input {
  box-shadow: 0 0 10px #f2f2ff;
}

.joinBeta input {
  box-shadow: 0 0 10px #f2f2ff;
}

.join-heading {
  font-family: 'Roboto', sans-serif !important;
  color: $prime-color;
  margin: auto;
  text-align: center;
  font-size: 2.5em;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 5px;
}

.joinBeta img {
  width: 250px;
}

footer p {
  color: rgba(0, 0, 0, 0.2);
  font-size: 20px;
}

footer img {
  color: rgba(0, 0, 0, 0.2);
  height: 20px;
}

.footerbg {
  background-color: rgb(248, 248, 248);
  padding-top: 60px;

  .container-sm {
    display: flex;
    padding: 4vh;
    justify-content: space-around;
  }
}

.discover {
  background-color: #fcfcfc;
}

// .text-center {
//   margin-top: 50px;
// }

// .col-md-6 {
//   display: flex;
//   justify-content: center;
// }

.header-context-wrapper {
  width: 100%;
}

.flex-d-space {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 56vh;
}

.main-container {
  width: 100%;
  background-position: 0 100px;
}

.main-bg {
  width: 100%;
}

.main-bg-img {
  background: url('../../assets/images/main-bg-w-btn.svg');
  height: 1050px;
  background-position: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: -20px;
}

.main-bg-img-m {
  background: url('../../assets/images/main-bg-m.svg');
  width: 100%;
  height: 875px !important;
  background-position: 100% 0vw;
  background-size: contain;
  background-repeat: no-repeat;
  margin: -90px 2%;
}

.bg-container {
  display: block;
  position: relative;
  top: 200px;
  left: 5%;
  width: 60%;
  margin-top: 30px;
}

.selected-btn {
  color: #162642 !important;
  font-weight: bold !important;
  background-color: $btn-bg-color !important;
  border: unset !important;
}

.normal-btn {
  font-weight: 400 !important;
  border: 2px solid $btn-bg-color !important;
  background-color: $white-color !important;
}

.home-btn {
  border-radius: 8px !important;
  color: $prime-color !important;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 24px;
  padding: 1em !important;
  margin-right: 2%;
  position: relative;
  top: -45px;
  &:hover {
    background-color: #f2f2ff !important;
    color: #162642 !important;
  }
}

.mention-wrapper {
  display: flex;
  justify-content: center;
  margin: 16vh 0;
  font-family: Roboto !important;
}

.af-btn-light {
  width: 100%;
  padding: 4px;
  color: $prime-color;
  background-color: $white-color;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: none;
    color: $hover-color;
    font-weight: bold;
    transform: scale(1.1);
  }
}

// font and color set up
.font-weight-700 {
  font-weight: 700;
}

.font-29 {
  font-size: 29px;
}

.font-23 {
  font-size: 23px;
}

.font-16 {
  font-size: 16px;
}

.font-39-lato {
  font-size: 34px;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 5px;
  font-family: 'Roboto', sans-serif !important;
  justify-content: left !important;
}

.font-23-lato {
  font-size: 23px;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif !important;
  justify-content: center !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-color-prime {
  color: $prime-color;
}

.font-color-secondary {
  color: $secondary-color;
}

.font-color-faded {
  color: $faded-color;
}

.btn-bg-color {
  background-color: $prime-color;
  outline: none !important;
}

.bg-color-light {
  background-color: $btn-bg-color;
}

.best-case-container {
  padding: 100px 0px !important;
}

// how it works part css setup
.how-it-works-container {
  height: 900px;
  width: 100%;
  align-items: center;

  .processLine {
    width: 35px;
  }

  .row {
    height: 220px;
    align-items: center;

    .img {
      display: flex;
      justify-content: left;
      align-self: center;
    }

    .img img {
      width: 185px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $prime-color;
  font-family: Roboto;
  margin: 5px 20px;
}

.logo-pic {
  display: inline-block;
  height: 36px;
  width: auto;
  margin-top: 10px;
  margin-left: 25px;
}

.signup-login .log-in {
  display: inline-block;
  margin: 10px 30px;
  font-size: 20px;
  font-weight: bold;
  color: #7076fa;
}

.log-in {
  display: inline-block;
  margin: 20px 30px;
  font-size: 20px;
}

.log-in:hover {
  cursor: pointer;
  color: #edc432;
}

.signup-login .sign-up {
  display: inline-block;
  margin: 15px 30px;
  font-size: 20px;
  font-weight: bold;
}

.sign-up:hover {
  cursor: pointer;
  color: #edc432;
  transform: scale(1.1);
}

.home-footer {
  width: 100%;
  height: 150px;
  background-color: #fcfcfc;
  color: rgba(0, 0, 0, 0.3);
}

.home-footer-top {
  border: 1px solid black;
  height: 40px;
  width: 300px;
  margin: 80px auto;
  font-size: 20px;
  font-family: Roboto;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.home-footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: 10px;
  font-size: 20px;
  font-family: Roboto;
  border: 1px solid black;
  height: 30px;
}

.terms-policy {
  display: flex;
  justify-content: space-between;
}

.carousel-indicators {
  bottom: -64px !important;
}

.carousel-inner {
  width: auto !important;
  height: 100% !important;
  min-height: 350px !important;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 0 !important;
}

.logo {
  .logo-tag {
    position: relative;
    top: 5px;
    font-size: 25px;
  }
}

.footer-bottom {
  flex-direction: row;
}

.tos-item1 {
  margin-right: 30px;
}

.tos-item2 {
  margin-left: 30px;
}

.flex-col {
  flex-direction: column;
  padding-bottom: 20px;
  p {
    font-size: 12px;
  }
  .d-flex {
    justify-content: center;
  }
}

.carousel-indicators li {
  display: inline-block;
  margin: 30px 10px 0px;
  text-indent: 0;
  cursor: pointer;
  border: none;
  box-shadow: inset 2px 2px 2px 2px #162642;
}
.carousel-indicators .active {
  width: 48px;
  height: 5px;
  margin: 30px 10px 0px;
  background-color: #162642;
}

.partner-image {
  height: 90px;
  margin: 8px;
}

.advantage-icon {
  height: 200px;
  margin: 1em 3em;
}

$bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);

.action-button {
  width: 150px;
  background-color: white;
  border: 0px;
  text-decoration: none;
  position: relative;
  color: #162642;
  &:before {
    content: '' !important;
    position: absolute !important;
    width: 90px !important;
    height: 2px !important;
    bottom: 0 !important;
    background-color: #162642 !important;
    transform: scaleX(0);
    transition: all 0.6s $bounce;
  }
  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }
}

@media only screen and (max-width: 1440px) {
  .home-btn {
    margin-top: 150px;
    font-size: 18px !important;
  }

  .btn-size-2 {
    margin-top: 25px;
    font-size: 18px !important;
    width: 280px !important;
  }
  .head_heading {
    margin-top: 50px;
    font-size: 45px;
  }
  .bg-container {
    display: block;
    top: 0px;
    left: 5%;
  }
  .flex-d-space {
    padding-bottom: 0px !important;
  }

  .main-bg-img {
    height: 700px !important;
  }

  .head_subtitle {
    width: 75%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .btn-size-2 {
    width: 260px !important;
  }

  .head_heading {
    font-size: 42px !important;
  }

  .flex-d-space {
    padding-bottom: 200px !important;
  }

  .carousel-inner {
    min-height: 200px !important;
  }

  .head_subtitle {
    width: 60%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .Knowledgeinfo {
    order: 2;
  }
  .Knowledge-img {
    order: 1;
  }
  .topBanerSection {
    .Gateway-info {
      order: 2;
      .getway-info-in {
        padding: 0 15px;
      }
    }
    .Gateway-img {
      order: 1;
      &.right {
        img {
          max-width: unset;
          margin: 0;
        }
      }
    }
  }

  .logo {
    .d-md-block {
      position: relative;
      left: -22%;
    }
    .logo-tag {
      position: relative;
      top: 0%;
      left: -22%;
      font-size: 25px;
    }
  }

  .carousel-inner {
    min-height: 350px !important;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .flex-dir-col {
    flex-direction: column;
  }

  .join-heading {
    font-family: 'Roboto', sans-serif !important;
    color: $prime-color;
    margin: auto;
    text-align: center;
    font-size: 1.5em;
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizeSpeed;
    letter-spacing: 5px;
  }

  .head_heading {
    width: 100% !important;
    font-size: 54px;
  }

  .head_heading_sub {
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    color: #b5b5b5;
  }

  .option {
    font-size: 17px !important;
  }

  .header-context-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: unset;
    margin: 0 2vw;
  }

  .bg-container {
    display: block;
    position: relative;
    top: 1%;
    left: 0;
    width: 100%;
  }

  .head_heading_sub {
    font-size: 17px !important;
  }

  .bg-container h4 {
    font-size: 17px;
    width: 80%;
  }

  .home-btn {
    font-weight: 400;
    font-size: 18px !important;
    border-radius: 5px;
    padding: 2vw 0vw;
    margin-top: 120px;
  }

  .btn-size-2 {
    margin-top: 40px !important;
    font-size: 18px !important;
    width: 300px !important;
  }
  .head_heading {
    margin-top: 20px !important;
    font-size: 45px !important;
  }

  h5 .option {
    color: $prime-color;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hover-color;
      text-decoration: none;
    }
  }

  .align-center {
    align-items: center;
  }

  .p-type {
    font-size: 16px;
  }

  .flex-d-space {
    padding-bottom: 0px !important;
  }

  .req-btn {
    text-align: center;
    font-size: 18px;
    margin: unset;
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizeSpeed;
    letter-spacing: 1px !important;
    padding: 0.7em 1em;
  }

  .btn-size-2 {
    padding: 0.7em;
    width: 260px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 4vh;
  }

  .main-para {
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    width: 100%;
    max-width: 289px;
    margin: auto;
    text-align: center;
    padding: 8vh 0;
    position: unset;
  }

  .font-29 {
    font-size: 27px;
    text-align: center;
  }

  .font-23 {
    font-size: 18px !important;
    text-align: center;
  }

  .font-16 {
    font-size: 25px;
    text-align: center;
    width: 84%;
    margin: auto;
  }

  .font-39-lato {
    font-size: 26px;
    justify-content: center !important;
  }

  .footerbg {
    padding: 0 30px;

    .container-sm {
      padding: 36px 10px;
      border-bottom: 1px solid #dededf;
      margin-bottom: 30px;
    }
  }

  .footer-bottom {
    //flex-direction: column !important;
    /* border: 1px solid black; */
  }

  .mb-1 {
    font-size: 14px;
    align-items: center;
  }

  .partner-image {
    height: 80px;
    margin: 10px;
  }
}

@media only screen and (max-height: 800px) and (max-width: 520px) {
  .head_heading_sub {
    margin-top: 15px !important;
  }

  .head_subtitle {
    width: 75%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .carousel-inner {
    min-height: 350px !important;
  }
}

@media only screen and (max-width: 520px) {
  .home-btn {
    margin-top: 50px !important;
    margin-bottom: -20px !important;
  }

  .option,
  .head_heading_sub {
    font-size: 16px !important;
  }

  .footerbg {
    .mobile-padding {
      margin-top: 35px !important;
      padding-bottom: 35px !important;
    }

    .mobile-small {
      padding-top: 15px !important;
    }
  }

  .head_heading_sub {
    margin-top: 5px !important;
  }

  .btn-size-2 {
    margin-bottom: 2px !important;
  }
  .bg-container {
    top: 2.5% !important;
  }

  .flex-d-space {
    margin-bottom: 180px !important;
  }

  .tos {
    .tos-item1,
    .tos-item2 {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .main-bg-img-m {
    height: 725px !important;
    margin-top: -100px !important;
  }

  .logo {
    .d-md-none {
      position: relative !important;
      left: -20% !important;
    }
    .logo-tag {
      position: relative !important;
      left: -20% !important;
      font-size: 25px !important;
    }
  }

  .bg-container {
    top: 40px !important;
    left: 10px !important;
  }

  .head_heading {
    width: 80% !important;
    font-size: 38px !important;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
  }

  .head_subtitle {
    width: 55% !important;
    font-size: 18px !important;
  }

  .req-btn {
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizeSpeed;
    letter-spacing: 0px !important;
  }

  .btn-size-2 {
    font-size: 20px !important;
    margin-top: 20px !important;
    width: 265px !important;
  }

  .font-39-lato {
    font-size: 22px !important;
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizeSpeed;
    letter-spacing: 2px !important;
  }

  .container-sm {
    padding: 0 !important;
  }

  .font-29 {
    font-size: 22px !important;
  }

  .font-16 {
    font-size: 18px !important;
  }

  .btn-primary,
  .btn,
  .next-button,
  .back-button {
    &:hover {
      transform: none !important;
      border: none !important;
    }
    &:active {
      transform: none !important;
      border: none !important;
    }
  }

  .back-button {
    &:hover {
      background-color: white !important;
      color: $prime-color !important;
    }
    &:active {
      background-color: white !important;
      color: $prime-color !important;
    }
  }

  .next-button {
    &:hover {
      background-color: $prime-color !important;
      color: white !important;
    }
    &:active {
      background-color: $prime-color !important;
      color: white !important;
    }
  }

  .partner-image {
    height: 70px;
    margin: 10px;
  }
}

@media only screen and (max-width: 414px) {
  .carousel-inner {
    min-height: 325px !important;
  }
}

@media only screen and (max-width: 375px) {
  .home-btn {
    margin-top: 110px !important;
  }

  .main-bg-img-m {
    height: 700px !important;
  }

  .carousel-inner {
    min-height: 300px !important;
  }

  .bg-container {
    top: 0% !important;
  }

  .head_heading {
    font-size: 36px !important;
  }

  .head_subtitle {
    width: 65% !important;
    font-size: 16px !important;
  }

  .btn-size-2 {
    margin-top: 0px !important;
    font-size: 18px !important;
    width: 240px !important;
  }

  .font-39-lato {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 360px) {
  .head_heading {
    font-size: 34px !important;
  }

  .btn-size-2 {
    font-size: 15px !important;
    width: 220px !important;
  }

  .font-39-lato {
    font-size: 19px !important;
  }

  .font-16 {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 320px) {
  .bg-container {
    left: 5px !important;
  }

  .home-btn,
  .option,
  .head_heading_sub {
    font-size: 14px !important;
  }

  .head_heading {
    width: 75% !important;
    font-size: 28px !important;
  }

  .req-btn {
    width: 200px !important;
  }

  .carousel-inner {
    min-height: 275px !important;
  }

  .head_subtitle {
    width: 70% !important;
    font-size: 14px !important;
  }

  .font-39-lato {
    font-size: 17px !important;
  }

  .font-29 {
    font-size: 19px !important;
  }

  .font-23 {
    font-size: 16px !important;
  }

  .font-16 {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 280px) {
  .home-btn,
  .option,
  .head_heading_sub {
    font-size: 13px !important;
    top: -65px !important;
  }

  .mobile-small {
    font-size: 12px !important;
  }

  .tos {
    p {
      font-size: 12px !important;
    }
  }

  .head_heading {
    margin-top: -50px !important;
  }

  .req-btn {
    width: 180px !important;
  }

  .carousel-inner {
    min-height: 225px !important;
  }

  .head_subtitle {
    font-size: 18px !important;
    margin-bottom: 0em;
  }

  .font-39-lato {
    font-size: 16px !important;
  }

  .font-29 {
    font-size: 18px !important;
  }

  .font-16 {
    font-size: 14px !important;
  }
}

.topBanerSection {
  background-color: #fff;
  padding-top: 6.5%;
  padding-bottom: 6.5%;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .btn-group {
      .btn {
        font-weight: bold;
        border-width: 2px;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .btn:nth-child(1) {
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        border-right-width: 0px;
      }
      .btn:nth-last-child(1) {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        border-left-width: 0px;
      }
      .active-btn {
        background-color: #3e4c63;
        color: white;
      }
    }
    h5 {
      text-align: left;
      font-weight: 300;
    }
  }
  .right {
  }
}

.ourCoInvesters {
  background-color: #f1f2f4;
  text-align: center;

  img {
    mix-blend-mode: darken;
  }

  h5 {
    font-weight: 300;
  }
}
.main-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 31px;
  column-gap: 42px;
  .img-item-list {
    min-width: 140px;
    @media (max-width: 767px) {
      min-width: 100px;
    }
  }
}
.img-gap {
  @media (max-width: 767px) {
    column-gap: 0;
  }
}
.ourPriorInvesters {
  background-color: #f1f2f4;
  text-align: center;

  img {
    mix-blend-mode: darken;
    height: auto;
  }

  h5 {
    font-weight: 300;
  }
}

.howItWorks {
  background-color: #fff;
  text-align: center;
  font-family: 'roboto';

  p {
    width: 80%;
    color: #172743;
    font-weight: 300;
    font-size: x-large;
    text-transform: capitalize;
    margin: auto;
  }
}

.benefits {
  // background-color: #f1f2f4;
  text-align: center;
  font-family: 'roboto';

  .benefitSet {
    .left {
      text-align: center;
    }
    .right {
      text-align: left;
    }
  }
  img {
    text-align: left;
  }
  p {
    color: #172743;
    font-weight: 300;
    font-size: x-large;
    text-transform: capitalize;
    text-align: left;
  }
}
.bottomBtnRequest {
  background-color: #f1f2f4;
}

.applyNowButton {
  display: inline-block;
  font-weight: 600;
  color: #162642;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #ffffff;
  border: 2px solid #162642;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.applyNowButton:hover {
  text-decoration: none;
  color: #fff;
  background-color: #162642;
}
.footer {
  background-color: #162642;
  color: #fff;
  text-align: center;
  padding: 22px 20px 20px 20px;
  a {
    color: #fff;
    padding: 0 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    &:nth-child(2) {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
    }
  }
  h4 {
    line-height: 1;
  }
}

.headerTop {
  .left {
    .logo {
      border: none;
    }
  }
  .right {
    a {
      color: #162642;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .applyNowButton {
      color: #162642;
    }
    .applyNowButton:hover {
      color: #fff;
    }
  }
}

@media (max-width: 767px) {
  .topBanerSection {
    .left {
      min-width: 100%;
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;

      .btn-group {
        display: inline-flex;
        justify-content: center;
        .btn {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          font-size: 1rem;
        }
        .btn:nth-child(1) {
          border-bottom-left-radius: 25px;
          border-top-left-radius: 25px;
          border-right-width: 0px;
        }
        .btn:nth-child(3) {
          border-bottom-right-radius: 25px;
          border-top-right-radius: 25px;
          border-left-width: 0px;
        }
        .active-btn {
          background-color: #3e4c63;
          color: white;
        }
      }
      h1 {
        font-size: 1.5rem;
      }
      h5 {
        text-align: center;
      }
    }
    .right {
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        width: 80%;
        margin: auto;
        display: flex;
        margin-bottom: 15%;
        margin-top: 10%;
      }
    }
  }
  .ourCoInvesters {
    img {
      margin: 1rem 0.5rem;
    }
  }
  .sectionHeader {
    font-size: 1.75rem;
    text-align: center;
  }
  // .sectionHeader::after {
  //   width: 15%;
  //   left: 42.5%;
  //   right: 42.5%;
  // }
  .howItWorks {
    .howItWorksSet {
      margin-bottom: 2.5rem;

      p {
        margin-top: 1rem;
      }
    }
  }
  .img-gap {
    row-gap: 7px;
  }
  .benefits {
    .benefitSet {
      .left {
        margin-bottom: 1rem;
      }
      .right {
        text-align: center;

        h5 {
          font-size: 1.65rem;
        }

        p {
          text-align: center;
          width: 75%;
          margin: auto;
        }
      }
    }
  }

  .whyDoIt {
    .whyDoItInnerSet {
      text-align: center;
      padding: 4rem 1rem;
      margin-bottom: 1.5rem;
    }
    .left {
      padding: 0rem ​0rem 2rem 0rem;
    }
    .right {
      p {
        font-size: x-large !important;
      }
    }
  }
}

.sectionHeader {
  color: #172743;
}
.sectionHeader::after {
  border-bottom: solid 2px #172743;
  position: absolute;
  width: 6%;
  content: '';
  top: 45px;
  left: 47%;
  right: 47%;
}
.whyDoIt {
  padding-top: 6.5%;
  padding-bottom: 6.5%;

  .whyDoItInnerSet {
    background-color: #f1f2f4;
    // padding: 2rem 3rem;
    margin-bottom: 2.5rem;
    min-height: 150px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        font-size: large;
      }
    }
  }
}
