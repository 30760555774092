div.slide-deck-modal-contents {
  padding-top: 15px;
  padding-bottom: 15px;
}

.progress {
  .progress-bar.bg-purple {
    background-color: #162642 !important;
  }

  .progress-bar-striped {
    background-size: 3rem 3rem;
  }
}

.d-none.d-lg-block .progress {
  width: 50%;
  margin: 0 auto;
}
