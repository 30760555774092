.suspend-container {
  .navbar-wrap {
    height: 117px;
    padding: 36px;
    .navbar-logo {
      max-width: 185px;
      height: auto;
    }
  }
  .account-suspend-wrap {
    height: calc(100vh - 237px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-weight: 400;
      font-size: 30px;
      line-height: 48px;
      color: #fc4a45;
    }
    .card-wrap {
      background: rgba(235, 235, 236, 0.2);
      border: 1px solid #eaecef;
      width: fit-content;
      padding: 24px;
      margin: 43px 0;
      max-width: 683px;
      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        color: #5d6166;
      }
      a {
        font-weight: 500;
        color: #3e6295;
        cursor: pointer;
      }
    }
    .return-btn {
      max-width: 348px;
      background: #162642;
      border-radius: 5px;
      border: 0;
      width: 100%;
      padding: 12px;
      color: #fff;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      &:hover {
        background: #243c64;
      }
    }
  }
}
