.main-img-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .main-img-wrap {
    gap: 20px;
  }
}
