@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-component-sectors {
  animation: fadeInOut 1s linear forwards;
  left: 0 !important;
  right: 0 !important;
  text-align: center;
}

.input-sector-container {
  animation: fadeInOut 1s linear forwards;
  margin: 0 auto;

  .card-component-sectors2 {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    color: #162642;
  }
}

.transparent-selection-layer {
  background-color: #162642;
  opacity: 0.3;
  z-index: 100;
  position: absolute;
  width: 190px;
  z-index: 100;
}

.milestones-container {
  text-align: center;
  margin: 10px auto;
  column-gap: 22px;
  row-gap: 25px;
  flex-wrap: wrap;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    row-gap: 16px;
  }
}

.milestone-card-background {
  background-color: transparent;
  column-gap: 15px;
  padding: 12px 20px;
}

.trending-card {
  margin-bottom: 40px !important;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: none !important;
  border: 1px solid #dededf;
}

.progress-card {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: none;
  border: 2px solid #dededf;
  display: flex;
  margin-bottom: 0;
  @media (max-width: 767px) {
    width: 100%;
  }
  svg {
    color: #3e6295;
  }
  &.selected2 {
    border: 2px solid #7c8e54;
    background: #f7f9f2;
    svg {
      fill: #7c8e54;
    }
  }
}
.title-trending-card {
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-transform: capitalize;
  color: #162642;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.selected-sector {
  color: #162642;
  font-weight: 500;
}

.border-sector-selected {
  border: 1px solid #162642;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.trending-card {
  margin-bottom: 40px !important;
  cursor: pointer;
  border-radius: 5px;
}
.innerTrendingCard {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;

  img {
    margin-right: 10px;
    width: 26px;
    height: auto;
  }
}

.title-sectors-container {
  border-radius: 5px;
}

.trending-card2 {
  width: 150px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .title-sectors-container,
  .trending-card,
  .trending-card2 {
    display: none;
  }
}

.trending-title {
  font-size: 18px;
  font-weight: 700;
}

.border-sector {
  border: 1px solid #b6b5b1;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}
.sectorIcon {
  width: 25px;
  height: auto;
  border: none;
  margin-right: 10px;
  margin-top: -2px;
}

@media only screen and (max-width: 1024px) {
  .input-sector-container,
  .card-component-sectors,
  .card-component-sectors2,
  .trending-title {
    display: block;
  }
}
