.login {
  animation: fadeIn 1s;
  .switch {
    position: absolute;
    top: 108px;
    right: 30%;
    transition: all 0.2s ease-in-out;
    float: right;
    &:hover {
      transform: scale(1.1);
      text-decoration: none;
    }
  }

  .switch-content {
    font-size: 15px;
    font-family: 'Roboto';
    text-align: left;
    color: #b5b5b5;
    position: relative;
    top: -34px;
    right: -70px;
    &:hover {
      text-decoration: none;
    }
  }

  .log-in-error {
    font-size: 12px;
    color: red;
    margin-top: 15px;
    text-align: center;
    font-family: 'Roboto';
    background-color: #ffe2e2;
    width: 100%;
    padding: 12px 15px;
    text-align: left;
    border-radius: 5px;
  }

  .log-in-error i {
    float: right;
    font-size: 16px;
    margin-top: -1%;
    font-family: 'Helvetica', 'Arial', sans-serif;
    &:hover {
      cursor: pointer;
    }
  }

  .close {
    display: none;
  }

  .sign-in-with-google {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Roboto';
  }

  .form-input {
    margin: 0 auto;
    width: 30vw;
    text-align: left;
    font-family: 'Roboto';
  }

  .google-login {
    box-shadow: none !important;
    border: 1px solid rgba(211, 211, 211, 0.7) !important;
    border-radius: 5px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  .group {
    position: relative !important;
  }

  .form-field {
    margin-bottom: 2px !important;
    margin-top: 25px !important;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto';
  }

  input::placeholder {
    font-size: 16px;
    font-family: 'Roboto';
  }

  .text-field-input {
    border: 1px solid rgba(117, 109, 109, 0.299);
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Roboto';
  }

  .google-login {
    box-shadow: none !important;
    border: 1px solid rgba(211, 211, 211, 0.7) !important;
    border-radius: 5px !important;
  }

  .passing-line-through {
    left: 93%;
  }

  .login-title {
    font-size: 32px;
    margin: 25px 0px;
    font-weight: 450;
    font-family: 'Roboto';
  }

  .responsive-width {
    font-size: 1vh;
    margin-bottom: 10px;

    span {
      padding: 0px 5px;
    }
  }

  .container {
    text-align: center;
    .or:after,
    .or:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
      text-decoration: line-through;
    }
  }

  .checkbox {
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
    label {
      position: relative;
      min-height: 34px;
      display: block;
      padding-left: 35px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px !important;
        font-weight: 500;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 4px;
        width: 22px;
        height: 22px;
        transition: transform 0.28s ease;
        border-radius: 3px;
        border: 1px solid;
      }
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 5px;
        border-bottom: 1px solid;
        border-left: 1px solid;
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.25s;
        position: absolute;
        top: 12px;
        left: 10px;
      }
    }
    input[type='checkbox'] {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
      margin-left: -20px;
      &:checked ~ label {
        &:before {
          border: 1px solid;
        }
        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }

  .forgot-password {
    font-weight: 700;
    margin-top: 15px;
    font-size: 16px;
    font-family: 'Roboto';
    color: #1d6ac5 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
.eye-image:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .login {
    .switch {
      right: 30%;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .login {
    .switch {
      right: 25%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .login {
    .switch {
      right: 20%;
      top: 110px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .login {
    .switch {
      right: 15%;
      top: 118px;
    }
  }
  .form-input {
    width: 85vw !important;
  }

  .sign-in-with-google {
    .form-input {
      padding: 0%;
      margin: 0% auto;
    }
  }

  .login-title {
    font-size: 38px;
    margin: 25px 0px !important;
  }

  .passing-line-through {
    left: 90% !important;
  }
}

@media only screen and (max-width: 575px) {
  .login {
    .switch {
      top: 108px;
      right: 7%;
    }
  }
}

@media only screen and (max-width: 430px) {
  .title {
    font-size: 22px !important;
  }
  .login-title {
    font-size: 27px !important;
    margin: 25px 0px !important;
  }
}

@media only screen and (max-width: 403px) {
  .switch-content {
    position: relative;
    right: 35%;
    top: 20px !important;
  }

  .forgot-password {
    font-size: 14px !important;
  }

  .container {
    text-align: center;
    .or:after,
    .or:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0' !important;
      text-decoration: line-through;
    }
  }
}

@media only screen and (max-width: 320px) {
  .switch {
    right: 5% !important;
  }

  .container {
    text-align: center;
    .or:after,
    .or:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
    \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0' !important;
      text-decoration: line-through;
    }
  }
}

@media only screen and (max-width: 280px) {
  .login {
    .switch {
      top: 75px !important;
    }
    .container {
      .or:after,
      .or:before {
        content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0
      \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
        text-decoration: line-through;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
