.bottomBtns {
}
.btn-primary {
  color: #fff;
  background-color: #162642;
  border-color: #162642;
}
.location-container {
  width: 100%;
}
.firstStep {
  margin-top: 0 !important;
}
.error-inpt {
  border: solid 1px red;
  outline: solid 1px red;
  color: red;
}
.saveAndLogout {
  border: 2px solid #162642;
  text-rendering: optimizeSpeed;
  letter-spacing: 1px !important;
  background-color: #fff;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  position: absolute;
  right: -40px;
  top: -105px;
}
.lastStepsDone {
  margin: 0 3rem;
  .ant-steps-item-icon {
    border: solid 1px #e1e1e2 !important;
    height: 32px !important;
    width: 32px !important;
    background-color: #e6ebdb !important;
    padding: 5px;
    svg {
      margin-top: -7px;
      width: 18px !important;
    }
  }
}

@media (max-width: 480px) {
  .lastStepsDone {
    margin: 0;
  }
}
