body,
html {
  /* overflow-x: hidden !important; */
  background-color: white;
}

/* input:focus,
textarea:focus,
button:focus {
  outline-color: #162642;
  outline-width: 1px;
  box-shadow: 0 0 1px 1px #162642;
  outline: none !important;
  box-shadow: none !important;
} */
.btnPrimary {
  color: #fff;
  background-color: #162642 !important;
  border-color: #162642 !important;
}

input,
input::placeholder {
  font-size: 16px !important;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
}
input.ant-input::placeholder,
textarea.ant-input::placeholder,
.ant-select-selection-placeholder {
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 22px !important;
  color: #343a40 !important;
  opacity: 0.7 !important;
}
.push {
  height: 70px;
}

button {
  outline: none !important;
}

/* font and color setup */

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-70 {
  font-size: 70px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-29 {
  font-size: 29px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-lato {
  font-family: 'Roboto', sans-serif !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.font-color-prime {
  color: #162642 !important;
}

.font-color-white {
  color: white !important;
}
.ant-message {
  z-index: 1030;
}

.ant-slider-tooltip {
  z-index: 1 !important;
}
