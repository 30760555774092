.LocationCard {
  border: solid 1px #d4d4d4;
  background-color: red;
  padding: 10px;
  width: 100%;
}

.location-card {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  overflow: hidden;
  min-width: 320px;
  max-width: 340px;
  padding: 5px ​15px;
  .transparent-location-layer {
    background-color: #162642;
    opacity: 0.3;
    z-index: 100;
    position: fixed;
    border-radius: 5px;
    right: -0.1px;
  }

  .left {
    padding-right: 0px;

    svg {
      padding: 15px 0px 15px 15px;
      height: 100%;
      width: 100%;
      color: #425a85;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 24px;
    gap: 5px;
    .city-title {
      font-weight: 400;
      font-size: 20px;
      text-transform: capitalize;
      line-height: 21px;
      color: #162642;
      margin: 0;
    }
    .state-title {
      line-height: 18px;
      font-weight: 400;
      font-size: 17px;
      text-transform: capitalize;
      color: #818a99;
      margin: 0;
    }
  }
}
.test {
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
}

.selected2 {
  border: 2px solid #7c8e54;
  background-color: #f7f9f2;
  .left {
    svg {
      color: #7c8e54;
    }
  }
  .right {
    .city-title {
      color: #504f4f;
    }
  }
}

@media only screen and (min-width: 520px) {
  .transparent-location-layer {
    width: 245px;
  }

  .test {
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 520px) {
  .transparent-location-layer {
    width: 170px;
  }
}
