.setExpLevel {
  border: solid 1px rgba($color: #000000, $alpha: 0.4);
  border-radius: 6px;
  min-height: 90px;
  padding-top: 8px;
  padding-bottom: 8px;

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90px;

    img {
      width: 60%;
      margin-left: 20%;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 90px;

    h5 {
      color: #162642;
    }
    p {
      color: #504f4f;
      font-size: 14px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90px;

    .ant-radio-inner {
      // width: 20px;
      // height: 20px;
      // border-color: #ccc;
      // border-width: 2px;
    }
    .ant-radio-checked {
      .ant-radio-inner::after {
        // top: -6px;
        // left: -6px;
        // display: table;
        // width: 20px;
        // height: 20px;
        // background-color: transparent;
        // content: url('../../assets/images/on_boarding_imgs/check.svg');
      }
    }
  }
}
.shareA_Bit {
  textarea {
    min-height: 150px;
  }
  textarea::placeholder {
    font-size: 1rem;
    line-height: 1.8rem;
    color: #939393;
    opacity: 0.7;
  }
}
.box {
  min-height: 90px;
  border: solid 1px red;
  width: 100%;
  border-radius: 4px;
}
.considerAsSet {
  border: solid 1px rgba(0, 0, 0, 0.4);
  width: 100%;
  border-radius: 4px;
  padding: 15px 25px;
  color: #343a40;
  cursor: pointer;
  // display: flex;
  align-items: center;
  margin-bottom: 25px;
  min-height: 270px;

  // &:hover {
  //   border: solid 2px rgba($color: #7c8e54, $alpha: 1);
  //   background: #f1f1f1;
  // }

  .anticon {
    vertical-align: 0.025em;
    text-align: right;
    right: 30px;
    position: absolute;
    top: 15px;
    font-size: larger;
  }

  .imgSet {
    // border: solid 1px rgba($color: #e40000, $alpha: 0.4);
    width: 120px;
    height: 120px;
    margin: auto;
  }

  .imgSetGen {
    background: url('../../assets/images/on_boarding_imgs/Generalist.svg') no-repeat center center scroll;

    &:hover {
      fill: red;
    }
  }
  .imgSetSpl {
    background: url('../../assets/images/on_boarding_imgs/Specialist.svg') no-repeat center center scroll;

    &:hover {
      fill: red;
    }
  }

  h5 {
    text-align: center;
    margin: 0;
    // margin-left: 20px;
  }
}

.lookingEngeenierCon {
  min-height: inherit;
  display: flex;
  padding: 8px 25px;
  svg {
    margin-right: 25px;
  }
}

.selectedConsiderAsSet {
  border: solid 2px rgba($color: #7c8e54, $alpha: 1);
  background: #f7f9f2;
}
.considerAsSet .ant-popover-inner-content {
  background: red !important;
  border-radius: 10px;
  color: white;
}

.ant-popover-inner {
  background-color: #f3f5f8;
  border-radius: 6px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: #f3f5f8;
  border-left-color: #f3f5f8;
}
.ant-popover-arrow {
  left: 2px;
  border-width: 10px;
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  display: none;
}

.thankYouSet {
  .left {
    img {
      // width: 65%;
      // margin: 0% auto auto auto;
      display: block;
      @media (max-width: 767px) {
        width: 50%;
        max-width: 50%;
      }
    }
  }
  .right {
    text-align: center;

    p {
      font-size: medium;
    }

    .checkMark {
      width: 60px;
      height: auto;
    }

    .middleSet {
      border: solid 1px rgba($color: #000000, $alpha: 0.2);
      border-radius: 6px;
      min-height: 100px;
      padding: 25px;
      // width: 85%;
    }
    .calenderImg {
      background: url('../../assets/images/on_boarding_imgs/calendar.svg') no-repeat center center;
      width: 120px;
      height: 120px;
      margin: auto;
      padding: 5px;

      h2 {
        margin-top: 45px;
        font-size: 2.2rem;
        line-height: 2.5rem;
        margin-bottom: 0px;
      }
      p {
        font-size: small;
      }
    }

    .input-group {
      width: 60%;

      .input-group-text {
        background-color: #7c8e54;
        border-color: #7c8e54;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        color: #fff;
        padding: 0.375rem 1.5rem;
      }
    }
  }
}
.AccreditionStatus {
  text-align: center;

  img {
    width: 80px;
    height: auto;
    margin-top: 10%;
  }

  h6 {
    margin: 3% 0 4% 0 !important;
    line-height: 2;
    font-weight: 400;
    font-size: larger;
  }

  p {
    margin-bottom: 3%;
    font-size: small;
  }

  .btn-primary {
    background-color: #162642;
    border-color: #162642;
    padding-left: 5%;
    padding-right: 5%;
    font-size: medium;

    &:hover {
      background-color: #162642;
      border-color: #162642;
    }
  }
  .btn-primary:focus {
    background-color: #162642;
    border-color: #162642;
  }
  .btn-primary:active {
    background-color: #162642;
    border-color: #162642;
  }
}
.investor-steps .ant-progress-status-success .ant-progress-bg {
  background-color: #7c8e54;
}
.InvestmentRange {
  .rangeSet {
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 4px;
    min-height: 150px;
    width: 100%;
    padding: 55px;
    margin-bottom: 6%;

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.InviteCode {
  .InnerSet {
    border: none;
    border-radius: 4px;
    min-height: 50px;
    width: 100%;
    padding: 10px 55px;
    background-color: #fff;

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.accreditionYesOrNo {
  .btnSet {
    .btn-light {
      background-color: #fff;
      border: solid 2px #162642;
      margin: 10px 10px;
      padding: 8px 30px;
    }
    .btn-light.active {
      background-color: #f7f9f2 !important;
      border: solid 2px #7c8e54 !important;
    }
  }
  .InnerSet {
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 4px;
    color: #343a40;

    h5 {
      font-weight: 400;
      color: #343a40;
    }
    h6 {
      font-weight: 400;
      color: #343a40;
    }
    p {
      font-weight: 400;
      font-size: medium;
    }
    ul {
      li {
        margin-bottom: 20px;
      }
    }
  }
}
// .ant-slider {
//   margin: 30px 0px 0px;
// }
.ant-slider-rail {
  background: #e7eade;
  border-radius: 20px;
  height: 10px;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #ccc;
}
.ant-slider-track {
  background-color: #162642;
  height: 10px;
  border-radius: 10px;
}
.ant-slider:hover .ant-slider-track {
  background-color: #162642;
}
.ant-slider-handle {
  width: 20px;
  height: 20px;
  background-color: #7c8e54;
  border-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ant-slider-handle-click-focused {
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #fff;
}
.ant-slider:hover .ant-slider-handle,
.ant-slider-handle:focus {
  background-color: #537508;
  border-color: #fff;
  box-shadow: unset;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fff;
}

.IdentifyAsAnyOne {
  button {
    border: solid 1px rgba($color: #000000, $alpha: 0.4) !important;
    background-color: #fff;
    padding: 10px 20px;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;

    &:hover {
      border: solid 1px rgba($color: #000000, $alpha: 0.4) !important;
      background-color: #fff;
    }

    &:active {
      transform: scale(0.9);
      border: solid 2px red;
    }
    &:focus {
      border: solid 2px #7c8e54;
      background-color: #f7f9f2;
    }
    img {
      width: 45px;
      height: auto;
      margin-right: 10px;
    }

    .anticon {
      vertical-align: 0.025em;
      margin-left: 10px;
    }
  }
}
.ant-btn.ant-btn-default {
  border: solid 2px #e1e1e1;
  padding: 10px 20px;
  color: #162642;
  border-radius: 6px;
  height: auto;
  margin: 10px;

  img {
    margin-right: 10px;
  }
}
.ant-btn.ant-btn-primary {
  border: solid 2px #7c8e54;
  padding: 10px 20px;
  color: #162642;
  border-radius: 6px;
  height: auto;
  margin: 10px;
  background: #f7f9f2;

  img {
    margin-right: 10px;
  }
}

.location-container {
  position: relative;
  // margin-bottom: 150px;

  .ant-input-prefix {
    margin-right: 10px;
  }
}
.locationAddIcon {
  position: absolute;
  border-left: solid 1px #d9d9d9;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  cursor: pointer;
}
.locationInterestList {
  position: relative;
  width: calc(100% - 0px);
  margin-top: 0px !important;
  min-height: 20px;
  max-height: 150px;
  overflow: auto;
  border: solid 1px #d9d9d9;

  /* Hide scrollbar for IE, Edge and Firefox */
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .locationRow {
    background-color: #fff;
    position: relative;
    border-bottom: solid 1px #d9d9d9;
    font-size: small;
  }
  .locationName {
    width: 90%;
    height: 30px;
    line-height: 30px;
    text-align: left;
    padding-left: 15px;
  }
  .close-circle {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: red;
  }
}

.Affiliations {
  position: relative;
  // margin-bottom: 150px;

  .ant-input-prefix {
    margin-right: 10px;
  }

  form {
    width: 100%;
    position: relative;

    .ant-input {
      width: 100%;
      height: 50px;
      padding-left: 20px;
      border-color: #162642;
    }
  }
  .InnerSet {
    border-radius: 4px;
    min-height: 150px;
    width: 100%;
    padding: 55px;
    background-color: #fbfbfb;
    margin-bottom: 3%;

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .AddIconAffiliation {
    position: absolute;
    border-left: solid 1px #162642;
    right: 0;
    top: 0;
    height: 50px;
    width: 60px;
    line-height: 45px;
    cursor: pointer;

    .btn {
      line-height: 34px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .AffiliationList {
    .ListRow {
      border-bottom: solid 1px #e5e5e5;
      font-size: small;
      overflow: hidden;

      &:nth-child(even) {
        background-color: #fff;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }

      .AffiliationName {
        width: 85%;
        float: left;
        line-height: 32px;
        padding-left: 20px;
      }
      .close-circle {
        idth: 14%;
        float: right;
        text-align: right;
        line-height: 32px;
        padding-right: 10px;
        color: red;
        cursor: pointer;
      }
    }
  }
}
.AffiliatedWith {
  .InnerSet {
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 4px;
    min-height: 150px;
    width: 100%;
    padding: 55px;
    background-color: #fbfbfb;

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
.done-step {
  // margin: 0 3rem;
}
.mainTimeContainer {
  display: flex;
  justify-content: space-between;
  .selectBoxTimeCon {
    .ant-select-selector {
      height: 52px;
      border-radius: 5px;
      .ant-select-selection-placeholder {
        padding-top: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: rgba(62, 76, 99, 0.65);
        opacity: 1;
      }
      .ant-select-selection-item {
        margin-top: 14px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: rgba(62, 76, 99, 0.65);
      }
      .ant-select-selection-search input {
        height: 50px;
      }
    }
  }
}
.timeContainerWork {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  width: fit-content;
  min-width: 260px;
  .ant-picker-input {
    input {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px !important;
      line-height: 26px;
      color: #504f4f;
    }
  }
  .startTimeText {
    padding: 0 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: rgba(62, 76, 99, 0.65);
  }
  .startTimeCon {
    border-color: #d9d9d9 !important;
    border: none;
    border-left: 1px solid;
    border-radius: 0;
    height: 52px;
    .ant-picker-suffix,
    .ant-picker-clear {
      svg {
        width: 20px;
        height: 20px;
        fill: #3e6295;
      }
    }
  }
}
.ant-picker-ok {
  .ant-btn.ant-btn-primary {
    border: none;
    padding: 0;
    color: #162642;
    margin: 0;
    background: inherit;
  }
}

@media (max-width: 820px) {
  .mainTimeContainer {
    flex-direction: column;
  }
  .timeContainerWork {
    min-width: 280px;
    margin-bottom: 20px;
    align-self: center;
  }
  .selectBoxTimeCon {
    width: 200px;
    min-width: 280px;
    align-self: center;
  }
}

@media (max-width: 768px) {
  .setExpLevel .left img {
    margin-left: 0;
  }
}
