.topBanerSection {
  background-color: #fff;
  padding-top: 130px;
  padding-bottom: 170px;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .btn-group {
      .btn {
        font-weight: bold;
        border-width: 2px;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .btn:nth-child(1) {
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        border-right-width: 0px;
      }
      .btn:nth-child(3) {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        border-left-width: 0px;
      }
      .active-btn {
        background-color: #3e4c63;
        color: white;
      }
    }
    .main-title {
      font-weight: 700;
      font-size: 41px;
      line-height: 41px;
      text-transform: capitalize;
      color: #3e4c63;
      margin: 61px 0 11px 0;
    }
    h5 {
      font-weight: 300;
      font-size: 23px;
      line-height: 39px;
      text-transform: capitalize;
      color: #162642;
      margin: 11px 0 16px 0;
    }
    .refer-btn {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      padding: 5px 36px;
    }
    .apply-btn {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      padding: 5px 25px;
    }
    .request-btn {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      padding: 5px 23px;
    }
  }
  .right {
    .img-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }
  }
}
.navbar-main-wrap {
  .navbar-collapse {
    .center-list {
      order: 1;
      gap: 15px;
    }
    .right {
      order: 2;
    }
  }
}
.ourCoInvesters {
  background-color: #f1f2f4;
  text-align: center;
  padding: 46px 0;
  img {
    mix-blend-mode: darken;
  }

  h5 {
    font-weight: 300;
    margin: 0 0 16px 0;
  }
}

.howItWorks {
  background-color: #fff;
  text-align: center;
  font-family: 'roboto';
  padding: 140px 0;
  p {
    width: 100%;
    color: #172743;
    font-weight: 300;
    font-size: 22px;
    text-transform: capitalize;
    margin: auto;
  }
}

.benefits {
  // background-color: #f1f2f4;
  text-align: center;
  font-family: 'roboto';
  padding: 80px 0 48px 0;
  .benefitSet {
    .icon-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 22px;
      width: 90%;
      &.icon-details-left {
        margin-left: auto;
      }
    }
    margin-bottom: 110px;
    .left {
      height: 80px;
      min-width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.icon-1 {
        img {
          width: 54px;
        }
      }
      &.icon-2 {
        img {
          width: 77px;
        }
      }
      &.icon-3 {
        img {
          width: 66px;
        }
      }
      &.icon-4 {
        img {
          width: 67px;
        }
      }
    }
    .right {
      text-align: left;
      h5 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 56px;
        text-transform: capitalize;
        margin: 0;
        // margin: 61px 0 15px 0;
      }
      p {
        margin: 0;
        font-weight: 300;
        font-size: 21px;
        line-height: 30px;
      }
    }
  }
  img {
    text-align: left;
  }
  p {
    color: #172743;
    font-weight: 300;
    font-size: x-large;
    text-transform: capitalize;
    text-align: left;
    line-height: 36px;
  }
}
.ourPriorInvesters {
  padding: 50px 35px;
  h5 {
    margin: 0 0 20px 0;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
  }
}
.applyNowButton {
  border-width: 2px !important;
}
.footer {
  background-color: #162642;
  color: #fff;
  text-align: center;
  padding: 48px 0 35px 0;
  img {
    height: 31px;
  }
  .socialIcons {
    padding: 0 0 48px 0;
  }
  h4 {
    padding-bottom: 24px;
    a {
      font-weight: 400;
      font-size: 24px;
      line-height: 21px;
    }
  }
  a {
    font-size: 19px;
    line-height: 21px;
    color: #ffffff;
    padding: 0 10px;
    font-weight: 300;
    &:nth-child(2) {
      font-weight: 300;
      font-size: 19px;
    }
  }
}
.bottomBtnRequest {
  background-color: #f1f2f4;
  padding: 72px 0;
}

.headerTop {
  .left {
    .logo {
      border: none;
    }
  }
  .right {
    a {
      color: #162642;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .applyNowButton {
      color: #162642;
      white-space: pre;
    }
    .applyNowButton:hover {
      color: #fff;
    }
  }
}

.sectionHeader {
  color: #172743;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.sectionHeader::after {
  border-bottom: solid 3px #172743;
  position: absolute;
  width: 80px;
  content: '';
  top: 45px;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
}
.whyDoIt {
  padding: 120px 0;

  .whyDoItInnerSet {
    background-color: #f1f2f4;
    margin: 25px 0;
    min-height: 200px;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        min-width: 100px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 42px;
      }
    }
  }
}
.howItWorks {
  .howItWorksSet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 42px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
@media screen and (max-width: 991px) {
  .benefits {
    .benefitSet {
      .icon-details {
        &.icon-details-left {
          margin-left: unset;
        }
      }
    }
  }
  .navbar-main-wrap {
    .navbar-collapse {
      .center-list {
        padding-bottom: 15px;
      }
      .right-list {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .navbar-main-wrap {
    .navbar-collapse {
      .center-list {
        padding-bottom: 0px;
      }
    }
  }
  .topBanerSection {
    padding-top: 60px;
    padding-bottom: 53px;
    .left {
      min-width: 100%;
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0;
      order: 2;
      .btn-group {
        display: inline-flex;
        justify-content: center;
        .btn {
          padding-left: 15px;
          padding-right: 15px;
          font-size: 1rem;
        }
        .btn:nth-child(1) {
          border-bottom-left-radius: 25px;
          border-top-left-radius: 25px;
          border-right-width: 0px;
        }
        .btn:nth-child(3) {
          border-bottom-right-radius: 25px;
          border-top-right-radius: 25px;
          border-left-width: 0px;
        }
        .active-btn {
          background-color: #3e4c63;
          color: white;
        }
      }
      .main-title {
        font-weight: 700;
        font-size: 30px;
      }
      h5 {
        text-align: center;
        font-weight: 300;
        font-size: 21px;
        line-height: 36px;
      }
      .page-text {
        padding: 0 35px;
      }
    }
    .right {
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      order: 1;

      img {
        width: 80%;
        max-width: 169px;
        margin: auto;
        display: flex;
        margin-bottom: 55px;
        // margin-top: 10%;
      }
    }
  }
  .ourPriorInvesters {
    padding: 40px 35px;
    h5 {
      margin: 0 0 42px 0;
    }
  }
  .ourCoInvesters {
    padding: 44px 50px;
    img {
      margin: 17px 0;
    }
    h5 {
      font-weight: 300;
      font-size: 22px;
      line-height: 22px;
      // margin-bottom: 0;
    }
  }
  .sectionHeader {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 0;
    &::after {
      width: 60px;
    }
  }

  .benefits {
    padding: 140px 0 48px 0;
    .benefitSet {
      margin-top: 110px;
      margin-bottom: 0;
      .left {
        margin: 0;
      }
      .right {
        margin: 0 45px;
        text-align: center;

        h5 {
          font-size: 1.65rem;
        }

        p {
          text-align: center;
          width: 100%;
          margin: auto;
        }
      }
      .icon-details {
        width: 100%;
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .whyDoIt {
    padding: 42px 0;
    .whyDoItInnerSet {
      text-align: center;
      padding: 4rem 1rem;
      margin: 50px 17px 0px 17px;
    }
    .left {
      padding: 0rem ​0rem 2rem 0rem;
    }
    .right {
      p {
        font-size: x-large !important;
      }
    }
  }
  .btn-primary:active,
  .btn:active,
  .next-button:active,
  .back-button:active {
    transform: none !important;
    border: none !important;
  }
  .howItWorks {
    padding: 63px 0;
    .howItWorksSet {
      padding: 0 17px;
      margin-top: 70px;
      margin-bottom: 0;
      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 37px;
      }
    }
  }
  .footer {
    img {
      height: 25px;
    }
    .socialIcons {
      padding: 0 0 48px 0;
    }
    h4 {
      padding-bottom: 24px;
      a {
        font-size: 21px;
        line-height: 21px;
      }
    }
    a {
      font-size: 14px;
      line-height: 21px;
      padding: 0 2px;
      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .whyDoIt {
    .whyDoItInnerSet {
      padding: 36px 15px 45px 15px;
      .right {
        p {
          text-align: center;
        }
      }
    }
  }
}

.text-capitalize {
  text-transform: capitalize;
}
