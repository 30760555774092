.settings {
  width: 800px;
  height: 600px;
  margin: 30px auto;
  font-family: Roboto;
}
.settings-title {
  font-family: Roboto;
  font-weight: 900;
  font-size: 38px;
  margin-bottom: 45px;
  margin-top: 30px;
}
.content-top {
  width: 800px;
  height: 170px;
  display: flex;
  justify-content: space-between;
}

.justify-content-start {
  text-align: left !important;
}

.switch1 {
  height: 20px;
  margin-bottom: 10px;
}
.switch {
  display: inline-block;
}

.switch-right {
  display: inline-block;
  margin-left: 10px;
}
.email-notifacation,
.login-info,
.display-profile-title,
.manage-account-title {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}
.email-me {
  margin-bottom: 10px;
}

.information {
  margin-right: 100px !important;
  width: 250px !important;
}

.pw {
  margin-bottom: 10px;
}
.change {
  border: 1px solid #162642 !important;
  color: #162642 !important;
  border-radius: 5px !important;
  position: relative;
  outline: none !important;
  font-size: 10px;
  font-weight: 600;
  font-family: Roboto;
}
.change:active {
  outline: none !important;
}

.login-bottom {
  display: flex;
  justify-content: space-between;
}
.login-bottom-right {
  margin-right: 50px;
  line-height: 30px;
}
.content-bottom {
  display: flex;
  justify-content: space-between;
}
.manage-account {
  width: 250px;
  margin-right: 100px;
}
.delete {
  color: #162642;
  text-decoration: none;
}
.delete:hover {
  text-decoration: none;
  color: #162642;
}
.display-profile-title,
.manage-account-title {
  margin-bottom: 10px;
}
.dot {
  height: 9px;
  width: 9px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.dots {
  position: fixed !important;
  margin-left: 80px !important;
  width: 160px;
}
.mymodal {
  height: 160px;
  width: 320px;
  background-color: white;
  z-index: 99;
  position: absolute;
  left: 0 px;
  top: 0 px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-title {
  height: 80px;
  padding: 15px 20px;
  font-weight: 700;
  text-align: center;
}
.mymodal-content {
  font-size: 15px;
  text-align: center;
}
.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.modal-button-left {
  border: 1px solid #162642;
  border-radius: 5px;
  width: 120px;
  height: 35px;
  text-align: center;
  padding-top: 6px;
  font-size: 13px;
  color: #162642;
  font-weight: bold;
  cursor: pointer;
}

.changepw-container {
  font-family: Roboto;
}
.changepw-content-title {
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  color: #162642;
  font-family: 'Roboto' !important;
  padding-top: 40px;
}

.changepw-content {
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
}

.backtosettings-bottom {
  background-color: #162642;
  text-align: center;
  height: 60px;
  width: 250px;
  margin: 80px auto;
  line-height: 60px;
  border-radius: 5px;
  font-weight: 600;
  font-family: Roboto;
}
.backtosettings-bottom Link {
  color: white;
}

.back-after-reset {
  display: block;
  margin: 75px auto 15px;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Roboto';
  font-size: 20px;
  border: 1px solid #162642;
}

@media only screen and (max-width: 1024px) {
  .dots {
    margin-left: 120px !important;
  }
}

@media only screen and (max-width: 375px) {
  .dots {
    margin-left: 65px !important;
  }
}

@media only screen and (max-width: 320px) {
  .dots {
    margin-left: 55px !important;
  }
}
