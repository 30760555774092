$font-default: 'Roboto';

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sectorHeading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  color: #504f4f;
}

.sector-text-color {
  color: #162642;
  border-color: #7c8e54;
  font-weight: 500;
  background-color: #f4f8ed;
  border-width: 2px;
}

.sector-text-color-nonselected {
  color: #333333;
  border-color: #333333;
  font-weight: 400;
}

.sector-text-color-nonselected[disabled] {
  color: #b5b5b5;
}

.question {
  text-align: center;
  animation: fadeInOut 1s linear forwards;
  font-family: $font-default !important;

  .steps {
    margin-right: 70vw;
    font-size: 15px;
    font-family: $font-default !important;
  }
  .step-question {
    margin: 0 auto 10px;
    font-weight: 400;
    color: #504f4f;
    .font-weight-bold {
      color: #343434;
    }
  }
  .small {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #9e9e9e;
  }
}

.push {
  height: 70px;
}

.buttons-container {
  background-color: white;
  z-index: 5;
  margin: 0 auto;
  width: 400px;
  position: fixed;
  left: 35%;
  bottom: 0;
  border: none;
  padding-top: 55px;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px 11px 8px -10px #ccc, 0px -11px 8px -10px #ccc;
}

.group-buttons {
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  z-index: 6;
  position: fixed;
  bottom: 0;
  margin-bottom: 7px;
  font-family: $font-default !important;

  .back-button {
    margin: 0 5px;
    background-color: white;
    border-color: #162642;
    color: #162642;
    font-family: $font-default !important;

    &:hover {
      background-color: white;
      color: #162642;
    }
  }

  .next-button {
    margin: 0 5px;
    width: 200px;
    background-color: #162642;
    border-color: #162642;
    color: white;
    font-weight: 700;
    font-family: $font-default !important;
    &:hover {
      background-color: #162642;
    }
    &:active {
      background-color: #162642;
    }
  }

  .btn-primary:disabled {
    color: #162642;
    background-color: white;
    border-color: #162642;
  }
}

.sector {
  animation: 1s fadeInOut linear forwards;
  border: 1px solid #e1e1e2;
  border-radius: 5px;
  margin: 0 auto;
  width: 340px;
  margin-bottom: 5px;
  padding: 2px;
  font-family: $font-default !important;
  .checkbox {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: block;

    label {
      font-family: $font-default !important;
      position: relative;
      min-height: 34px;
      display: block;
      padding-left: 40px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;
      color: red;

      span {
        font-family: $font-default !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px !important;
        font-weight: 500;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 4px;
        height: 28px;
        transition: transform 0.28s ease;
        border-radius: 50%;
        border: 1px solid #dededf;
        width: 28px;
      }
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 10px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.5s;
        position: absolute;
        top: 11px;
        left: 10px;
      }
    }
    input[type='checkbox'] {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
      margin-left: -20px;
      &:checked ~ label {
        &:before {
          border: 1px solid red;
          background-color: #162642;
        }
        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }
}

.selected span {
  font-family: $font-default !important;
  margin: 0px !important;
  padding: 0px !important;
}

@media only screen and (min-width: 1025px) {
  .sector {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .sector {
    width: 300px !important;
  }
}

@media only screen and (max-width: 420px) {
  .buttons-container {
    left: 0% !important;
  }
}

.question {
  text-align: center;
  margin-bottom: 10px;
  animation: fadeInOut 1s linear forwards;

  .steps {
    margin-right: 69vw;
    font-size: 15px;
  }
}

@media only screen and (min-width: 767px) {
  .question {
    text-align: center;
    margin-bottom: 10px;
    animation: fadeInOut 1s linear forwards;

    .steps {
      margin-right: 0vw;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .interests {
      text-align: center;
      margin-left: 30px;
      font-size: 24px;
      font-weight: 100;
    }
  }
}

@media only screen and (max-width: 520px) {
  .interests {
    margin-right: 35vw !important;
  }

  .interests2 {
    margin-right: 40vw !important;
    font-size: 16px !important;
  }

  .sector {
    position: relative !important;
    top: 45px !important;
  }
}

@media only screen and (max-width: 480px) {
  .question {
    .step-question {
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 420px) {
  .question {
    .step-question {
      font-size: 15px !important;
    }
  }
}
