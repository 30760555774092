.position-buttons {
  border-radius: 4px;
  border: 1px solid #dededf;
  background-color: #fff;
  color: black;
  font-family: 'Roboto';
  width: 90%;
  height: 40px;

  &:hover {
    // transform: scale(1.1);
  }
}
.position-buttons img {
  display: none;
}

.edit-list-buttons {
  float: left;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-family: 'Roboto';
}

.selected-position {
  border-radius: 4px;
  border: 1px solid #162642;
  background-color: #f7f9f2;
  color: #353535;
  font-family: 'Roboto';
  width: 90%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    // transform: scale(1.1);
  }
}
.selected-position img {
  display: block;
  margin-left: 10px;
}

@media only screen and (max-width: 406px) {
  .position-buttons {
    margin-left: 2px !important;
    margin-right: 2px !important;
    padding: 6px 8px !important;
  }

  .selected-position {
    margin-left: 2px !important;
    margin-right: 2px !important;
    padding: 6px 8px !important;
  }
}

@media only screen and (max-width: 375px) {
  .edit-list-buttons {
    margin-left: 2px !important;
    margin-right: 2px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 320px) {
  .edit-list-buttons {
    font-size: 12px !important;
    padding: 6px 11px !important;
  }
}
