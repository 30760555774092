$prime-color: #162642;

.settings-mobile-container {
  margin-top: 100px;
  position: fixed;
  overflow: hidden;
}

.settings-mobile-header {
  display: block;
  margin: auto;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
}

.settings-mobile-option {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-family: Roboto;
  font-size: 18px;
  &:hover {
    background-color: white !important;
  }
}

.full-mobile-button {
  width: 95%;
  display: block;
  margin: 15px auto;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  border: 1px solid $prime-color;
}

.mobile-primary {
  background-color: $prime-color;
  color: white;
}

.mobile-secondary {
  background-color: white;
  color: $prime-color;
}

@media screen and (max-width: 414px) {
  .settings-mobile-option {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 375px) {
  .settings-mobile-option {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 320px) {
  .settings-mobile-option {
    font-size: 13px !important;
  }
}
