.delete-container {
  padding-top: 5rem;
  overflow: hidden;
}

.investor-delete-page {
  margin: 0 auto;
}

.delete-title {
  font-size: 42px;
  font-family: Roboto;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
}
.delete-input-div {
  margin: 10px auto;
  width: 400px;

  height: 80px;
}
.button-border {
  margin: -22px auto;
  width: 400px;
}

.delete-input {
  border: 1px solid lightGrey;
  font-size: 18px;
}

.delete-input::placeholder {
  font-size: 18px;
}

.delete-input:hover {
  border-color: #162642;
}

.delete-button {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 20px;
}

.delete-button-active {
  background-color: #162642;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.delete-account-button {
  width: 400px;
  background-color: #162642;
  border-radius: 5px;
  height: 60px;
  margin-top: 2px;
  text-align: center;
  color: white;
  font-family: Roboto;
  font-weight: 600;
  line-height: 60px;
  border: none;
  outline: none;
}
.delete-account-button:hover {
  cursor: pointer;
}
.delete-account-button:disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.1);
  border: none;
}
::placeholder {
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
  margin-left: 10px;
}

.delete-refer-input {
  width: 400px;
  margin-bottom: 50px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 20px;
}

.delete-refer-input:hover {
  border: 1px solid #162642;
}
.delete-back {
  margin-left: 90%;
  border: 1px solid #162642;
  background-color: white;
  width: 100px;
}
.delete-back Link {
  color: #162642;
}
.delete-back Link:hover {
  text-decoration: none;
}
.investor-delete-confirm-page {
  margin: 60px auto;
  max-width: 800px;
  width: 90%;
  height: 600px;
}
.delete-confirm-title {
  margin: 0 auto;
  font-size: 30px;
  font-weight: 600;
  font-family: Roboto;
  width: 460px;
}
.already-delete-confirm-title {
  margin: 0 auto;
  font-size: 30px;
  font-weight: 600;
  font-family: Roboto;
  width: 800px;
  font-size: 40px;
}
.title-2 {
  margin: 0 auto;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}
.delete-confirm-content {
  margin: 0 auto;
  font-size: 25px;
  font-weight: 500;
  font-family: Roboto;
  width: 550px;
}
.already-delete-content {
  margin: 0 auto;
  font-size: 25px;
  font-weight: 500;
  font-family: Roboto;
  width: 450px;

  text-align: center;
  margin-top: 30px;
  margin-bottom: 80px;
  font-size: 30px;
}

.backtosettings-bottom-confirm {
  background-color: #162642;
  text-align: center;
  height: 60px;
  max-width: 300px;
  width: 90%;
  margin: 50px auto 0px auto;
  line-height: 60px;
  border-radius: 5px;
  font-weight: 600;
  font-family: Roboto;
  font-size: 20px;
}
.delete-my-account {
  text-align: center;
  height: 60px;
  max-width: 300px;
  width: 90%;
  margin: 10px auto;
  line-height: 60px;
  border-radius: 5px;
  font-weight: 600;
  font-family: Roboto;
  font-size: 20px;
  border: 1px solid #162642;
  color: #162642;
}

@media only screen and (max-width: 767px) {
  .delete-container {
    padding-top: 200px !important;
  }

  .delete-msg {
    width: 90%;
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .delete-container {
    position: fixed;
  }
}

@media only screen and (max-width: 375px) {
  .delete-msg {
    width: 90%;
  }
}

@media only screen and (max-width: 360px) {
  .delete-container {
    padding-top: 100px !important;
  }

  .delete-msg {
    width: 85%;
    font-size: 27px;
  }

  .delete-input {
    font-size: 15px !important;
  }

  .delete-input::placeholder {
    font-size: 15px !important;
  }
  .delete-button,
  .delete-button-active {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 354px) {
  .delete-msg {
    width: 90%;
  }
}

@media only screen and (max-width: 320px) {
  .investor-delete-confirm-page {
    margin-top: 0px !important;
    position: relative;
    top: -20px;
  }
}

@media only screen and (max-width: 280px) {
  .delete-msg {
    width: 90%;
    font-size: 22px !important;
  }
}
