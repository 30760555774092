.onboarding-header-main-wrap {
  .logo {
    height: 45px;
    width: auto;
    @media (max-width: 767px) {
      height: 30px;
    }
  }
  .onboardingHeaderLogo {
    background: url(/static/media/LogoExitFund.3d12dd21.svg);
    height: 45px;
    width: 230px;
    background-repeat: no-repeat;
    @media (max-width: 767px) {
      width: 50px;
    }
  }
  .authHeaderLogo {
    background: url(/static/media/LogoExitFund.3d12dd21.svg);
    height: 45px;
    width: 230px;
    background-repeat: no-repeat;
  }
  .save-logout-btn {
    border: 2px solid #3e4c63 !important;
    padding: 5px 12px;
    border-radius: 26px;
    background: transparent;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #3e4c63 !important;
    display: flex;
    align-items: center;
    box-shadow: none;
    .ant-btn-loading-icon {
      margin-right: 15px;
      .anticon {
        padding-right: 0;
      }
    }
    @media (max-width: 375px) {
      font-size: 13px;
      padding: 4px 8px;
      line-height: 18px;
    }
  }
}
