.sticky-element > div {
  z-index: 1000;
}

.profile-top {
  height: 75px;
  width: 62%;
  margin: 10px auto 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.profile-top-text {
  font-size: 38px;
  margin: 15px 0px;
  font-family: Roboto;
  font-weight: 700;
}
.profile-top-right {
  width: 30%;
}
.pdf-icon:hover {
  cursor: pointer;
}
.profile-top-right-link1 {
  border: 1px solid #162642;
  margin-top: 20px;
  display: inline-block;
  border-radius: 7px;
  height: 40px;
  font-size: 20px;
  width: 65%;
  text-align: center;
  line-height: 40px;
  color: #162642;
}
.profile-top-right-link1:hover,
.profile-top-right-link1:active {
  text-decoration: none;
  color: #162642;
}
.profile-top-right-link2:hover,
.profile-top-right-link2:active {
  text-decoration: none;
  color: #162642;
}
.profile-top-right-link2 {
  border: 1px solid #4430e2;
  margin-top: 20px;

  border-radius: 7px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  width: 30%;
  margin-left: 5px;
  text-align: center;
  line-height: 40px;
  color: #4430e2;
  float: right;
}

.name-location {
  height: 160px;
  width: 62%;
  margin: 15px auto 30px auto;
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
}
.photo-name {
  display: flex;
  justify-content: space-between;
  width: 350px;
}
.photo-name img {
  height: 100px;
  margin-left: 35px;
  margin-top: 30px;
}
.name {
  margin-left: 27px;

  width: 350px;
  margin-top: 9px;
}

.full-name-top {
  font-size: 25px;
  margin-top: 35px;
  margin-left: 8px;
  font-weight: 700;
  font-family: Roboto;
}
.full-name-bottom {
  font-size: 17px;
  margin-left: 10px;
  margin-top: 15px;
}

.full-name-bottom-text-edit {
  font-size: 17px;
  margin-left: 1px;
  margin-top: 15px;
}
.location {
  margin-right: 10px;
  width: 40%;
  margin-top: 7px;
}
.location-top {
  margin-top: 36px;
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
}
.location-bottom {
  font-size: 17px;
  margin-top: 15px;
}

.short-bio {
  border: 1px black solid;

  width: 62%;
  margin: 30px auto;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
  padding-bottom: 30px;
}

.short-bio-title {
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-left: 25px;
  margin-top: 20px;
  margin-right: 25px;
}
.short-bio-text {
  margin-left: 40px;
  margin-top: 9px;
  margin-right: 40px;
  font-size: 19px;
}
.section-preference {
  border: 1px black solid;
  padding-bottom: 20px;
  width: 62%;
  margin: 30px auto;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
}
.section-preference-title {
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-left: 25px;
  margin-top: 20px;
}
.section-preference-content {
  margin-left: 40px;
  margin-top: 9px;
  margin-right: 40px;
  overflow: wrap;
}

.profile-top-edit {
  height: 75px;
  width: 62%;
  margin: 10px auto 0px auto;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.profile-top-text-edit {
  font-size: 38px;
  margin: 15px 0px;
  font-family: Roboto;
  font-weight: bold;
}

.profile-top-right-edit {
  width: 30%;
}
.profile-top-right-link1-edit {
  border: 1px solid #162642;
  margin-top: 20px;
  display: inline-block;
  border-radius: 7px;
  height: 40px;
  font-size: 20px;
  width: 65%;
  text-align: center;
  line-height: 40px;
  color: #162642;
}
.profile-top-right-link1-edit:hover,
.profile-top-right-link1-edit:active {
  text-decoration: none;
  color: #162642;
}
.profile-top-right-link2-edit:hover,
.profile-top-right-link2-edit:active {
  text-decoration: none;
  color: #162642;
}
.profile-top-right-link2-edit {
  border: 1px solid #4430e2;
  margin-top: 20px;

  border-radius: 7px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  width: 30%;
  margin-left: 5px;
  text-align: center;
  line-height: 40px;
  color: #4430e2;
  background-color: #4430e2;
  color: white;
  float: right;
}

.name-location-edit {
  height: 180px;
  width: 62%;
  margin: 15px auto 30px auto;
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
}
.photo-name-edit {
  display: flex;

  width: 350px;
}

.img-container {
  position: relative;
  text-align: center;
  color: white;
}
.centered-img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -50%);
}
.centered-img-text-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-img-change,
.centered-img-text-1 {
  font-size: 16px !important;
  width: 60px !important;
  font-family: Roboto;
  font-weight: 600 !important;
  white-space: nowrap;
}

.remove-pic {
  font-size: 13px !important;
  color: #b5b5b5 !important;
  background-color: white !important;
  border: white !important;
}

.unfilled-msg {
  font-size: 16px;
  color: red;
  position: relative;
  font-weight: 900;
  font-family: 'Roboto' !important;
  top: 10px;
}

.unfilled {
  border: 2px solid red !important;
}

.profile-img-change:hover {
  cursor: pointer;
}

.centered-img-text:hover {
  cursor: pointer;
}
.centered-img-text-1:hover {
  cursor: pointer;
}
.invest-photo-laber {
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: -10px;
  color: grey;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  line-height: 50px;
  position: relative;
  top: -100px;
  right: -16px;
}
.photo-edit {
  width: 250px !important;
  margin-right: -23px;
  position: relative;
}
.remove-photo {
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 12px;
  padding-left: 30px;
  padding-top: 10px;
}
.investor-default-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 30px;
  margin-left: 35px;
}
.upload-photo-1 {
  border: none;
  border-radius: 7px;
  color: #162642;
  margin-bottom: 10px;
  top: 135px;
  right: -15px;
  position: absolute;
  font-size: 13px;
}
.name-edit {
  margin-left: 55px;

  width: 350px;
  margin-top: 26px;
}
.full-name-top-edit {
  font-size: 25px;
  margin-top: 25px;

  font-weight: 700;
  font-family: Roboto;
}
.full-name-bottom-edit {
  font-size: 17px;

  margin-top: 15px;
  height: 50px;
  border-radius: 7px;
  width: 120%;
  border: 1px solid #b5b5b5;
}
.photo {
  border: 1px solid black;
  height: 50px;
  width: 50px;
}
.investor-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.location-edit {
  margin-right: 4%;
  width: 37%;
  margin-top: 23px;
}
.location-top-edit {
  margin-top: 25px;
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-bottom: 10px;
}
.location-top-edit-1 {
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-bottom: 10px;
}
.location-bottom-edit {
  display: flex;
  justify-content: flex-start;
}
.location-bottom-input-edit {
  font-size: 17px;

  height: 50px;
  border-radius: 7px 0 0 7px;
  width: 100%;
  border: 1px solid #b5b5b5;
  border-right: none;
  padding-left: 20px;
}

.location-bottom-input-edit:hover {
  border: #162642 1px solid;
  border-right: none;
}
.location-button-edit {
  width: 20%;
  border-radius: 0 7px 7px 0;
  border: 1px solid #b5b5b5;
  border-left: none;
}

.bubble {
  border: #162642 1px solid;
  padding: 10px 15px;
  color: #162642;
  border-radius: 50px;
  margin-right: 5px;
}

.short-bio-edit-1 {
  border: 1px black solid;
  width: 62%;
  margin: 30px auto;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
}

.short-bio-title-edit {
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-left: 4%;
  margin-top: 20px;
}
.short-bio-text-edit {
  margin-left: 4%;
  margin-top: 18px;
  margin-right: 4%;
  font-size: 19px;
}
.short-bio-text-content-edit {
  height: 90px;
  width: 100%;
  border-radius: 7px;
  border: 1px solid #b5b5b5;
  padding-left: 10px;
  font-size: 16px;
  padding-top: 10px;
}

.short-bio-text-content-edit:hover {
  border: #162642 1px solid;
}

.section-preference-edit {
  border: 1px black solid;
  padding-bottom: 20px;
  width: 62%;
  margin: 30px auto;
  border-radius: 7px;
  border: 1px #b5b5b5 solid;
}
.section-preference-title-edit {
  font-size: 25px;
  font-weight: 700;
  font-family: Roboto;
  margin-left: 4%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.edit-preference {
  border-radius: 7px;
  width: 180px;
  color: white;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  border: 2px solid #162642;
  margin-right: 4%;
  text-align: center;
}
.section-preference-content-edit {
  justify-content: space-between;
  margin-top: 9px;
  margin-right: 40px;
  margin-left: -1%;
}

.section-preference-content-each-edit {
  border: 1px solid #4430e2;
  border-radius: 7px;
  padding: 10px 10px;
  font-size: 16px;

  color: #4430e2;
  font-weight: 700 !important;
  height: 45px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 5px;
  font-family: Roboto;
}

.fa-search {
  content: '\00d7';
  font-size: 20px;
  color: #edc432;
}
.done-edit {
  margin-left: 2%;
  height: 50px;
  background-color: #162642;
  border-radius: 7px;
  width: 12%;
  color: white;
  font-weight: bold;
  margin-right: 4%;
}
.done-edit:disabled {
  background-color: #dededf;
  border: none;
  color: #f3f3f3;
}
.remove-file-button {
  border: none !important;
  background-color: transparent !important;
  color: grey !important;
  font-size: 19px !important;
}

.replace-file-button {
  background-color: transparent !important;
  border: 2px solid #162642 !important;
  color: #162642 !important;
  font-weight: bold !important;
}

.upload-button {
  color: #162642;
  border: 2px solid #162642;
  border-radius: 5px;
}

.add-cofounder-border {
  border: 1px solid #162642 !important;
  border-radius: 5px;
  text-align: center;
  color: #162642;
  height: 100px;
}

.add-cofounder-card:hover {
  opacity: 0.8;
  cursor: pointer;
}

.upload-button:hover {
  cursor: pointer;
  opacity: 0.6;
}
.save-button:hover {
  cursor: pointer;
}
.active-team-num {
  border-color: #162642 !important;
  background-color: #162642 !important;
  font-weight: bold !important;
}
.inactive-team-num {
  background-color: #dededf !important;
  border-color: #dededf !important;
  color: black !important;
}
.profile-top-right-link1:hover {
  opacity: 0.8;
}
.profile-top-right-link1-edit:hover {
  opacity: 0.8;
}
.profile-top-right-link2:hover {
  opacity: 0.8;
}
.profile-top-right-link2-edit:hover {
  opacity: 0.8;
  color: white;
}

h3,
h5,
p {
  font-family: Roboto;
}

.dashboard-container h4 {
  width: 80%;
  margin: auto;
  font-weight: 400;
}

button,
.complete-button {
  font-family: Roboto;
  outline: none !important;
}

.fade-enter div {
  opacity: 0;
}
.fade-enter-active div {
  opacity: 1;
}
.fade-exit div {
  opacity: 1;
}
.fade-exit-active div {
  opacity: 0;
}
.fade-enter-active div,
.fade-exit-active div {
  transition: opacity 500ms, transform 500ms;
}

.table-header,
.table-row-item {
  border: 0 !important;
}

.purple-colored {
  color: #162642;
}

.red-colored-border {
  border-color: red !important;
}

.mobile-row {
  display: flex;
  flex-direction: column;
}

.mobile-toggle-button {
  float: right;
  position: relative;
  z-index: 99;
  font-weight: 600;
  padding: 7px 20px;
  font-size: 16px;
  font-family: Roboto;
  border-radius: 5px;
  outline: none !important;
}

.mobile-edit {
  color: #162642 !important;
  border: 1px solid #162642 !important;
  background-color: white !important;
  outline: none !important;
}

.mobile-save {
  color: white !important;
  border: 1px solid #162642 !important;
  background-color: #162642 !important;
  outline: none !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.mobile-header {
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
}

.mobile-info {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;
  margin: 0 15px;
  text-align: left;
}

.mobile-info-no-margin {
  font-family: Roboto;
  font-weight: 400px;
  font-size: 16px;

  input::placeholder {
    font-family: Roboto;
    font-weight: 400px;
    font-size: 16px;
  }
}

.pt-3,
.card-text {
  input::placeholder {
    font-size: 16px !important;
    color: #b5b5b5;
  }
}

.mobile-list {
  border: 1px solid #162642;
  border-radius: 30px;
  padding: 10px 20px;
  color: #162642;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 10px;
}

.investor-profile-pic {
  border-radius: 50%;
  height: 120px !important;
  width: 120px !important;
}

.investor-preview-button[disabled] {
  color: #c9c9c9 !important;
  border-color: #c9c9c9 !important;
}

.d-block[aria-current='page'] {
  color: #c9c9c9 !important;
  border-color: #c9c9c9 !important;
}

.investor-web-list {
  border: 1px solid #162642;
  border-radius: 30px;
  padding: 10px 20px;
  color: #162642;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 5px;
}

.investor-linkedin-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 65px !important;
  height: 65px !important;
}

.investor-save-button {
  background-color: #162642 !important;
  border: 1px solid #162642 !important;
  outline: none !important;
  color: white !important;
  padding: 8px 20px !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
  margin-top: 10px;
}

.remove-cofounder-button {
  border: 1px solid #162642 !important;
}

.investor-edit-change-button {
  color: #162642 !important;
  text-decoration: none !important;
  border: 2px solid #162642 !important;
  padding: 15px 25px !important;
  border-radius: 10px !important;
  font-family: Roboto;
  font-weight: 700 !important;
  position: relative !important;
}

.add-cofounder-button {
  top: 0px;
  position: relative;
}

.dashboard-container-preview a,
.mobile-seemore,
.slide-deck-size {
  font-size: 19px !important;
}

.mobile-row {
  font-family: Roboto;
}

.plusicon {
  height: 30px !important;
  margin-top: -25px !important;
}

.investor-page-title {
  font-size: 36px !important;
}

.add-cofounder-card .add-cofounder-text,
.dashboard-container-preview h1,
.container-preview-investor h1 {
  font-size: 20px !important;
}

.investor-profile-edit-title,
.investor-web-team h3,
.dashboard-container-preview h2,
.container-preview-investor h2,
.button-left,
.button-right {
  font-size: 22px !important;
}

.investor-preview-button,
.investor-edit-button,
.investor-profile-edit-text,
.investor-save-button,
.investor-web-team h5,
.remove-file-button,
.investor-edit-change-button,
.dashboard-container-preview h4,
.container-preview-investor h4,
.investor-card-section-title {
  font-size: 18px !important;
}

.investor-web-list,
.investor-web-team h6,
.remove-cofounder-button,
.dashboard-container-preview h5,
.container-preview-investor h5,
.container-preview-investor a,
.dashboard-container-preview a,
.mobile-seemore,
.link-preview,
.slide-deck-size {
  font-size: 16px !important;
}

.ml-25 img {
  width: 80px !important;
}

.sector-list-size,
.preview-team-position {
  font-size: 12px !important;
}

.ipad-positions {
  font-size: 16px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.add-cofounder-card .add-cofounder-text {
  font-size: 20px !important;
  margin-top: 10px !important;
}

.add-cofounder-button {
  font-size: 35px !important;
}
.how-many-buttons {
  padding: 6px 33px !important;
  margin-right: 3px !important;
}

.ipad-positions {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

@media only screen and (max-width: 1199px) {
  .add-cofounder-card .add-cofounder-text {
    font-size: 18px !important;
    margin-top: 10px !important;
  }

  .add-cofounder-button {
    font-size: 30px !important;
  }

  .py-3 .how-many-buttons {
    padding: 5px 25px !important;
    margin-right: 3px !important;
  }

  .d-flex .ipad-positions {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  .investor-page-title {
    font-size: 32px !important;
  }

  .mx-auto .investor-profile-edit-title {
    font-size: 20px !important;
  }

  .card-text h5 {
    font-size: 17px !important;
  }

  .investor-profile-edit-text,
  .pb-4 .investor-profile-edit-text,
  .profile-edit .investor-web-team .pt-2 {
    font-size: 16px !important;
  }

  .card-text .investor-profile-edit-text .checkbox span,
  .pb-4 .investor-save-button,
  .pb-4 .investor-preview-button,
  .pb-4 .investor-edit-button,
  .pb-4 .investor-save-button,
  .investor-add-button,
  .px-5 .investor-edit-change-button,
  .card-body .investor-edit-change-button,
  .card-text .remove-file-button {
    font-size: 14px !important;
  }

  .investor-profile-edit-sectors,
  .py-2 .investor-web-list,
  .centered-img-text-1,
  .profile-img-change {
    font-size: 12px !important;
  }

  .remove-pic,
  .unfilled-msg {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 991px) {
  .upload-button {
    position: relative !important;
    top: -70px !important;
  }

  .py-3 .how-many-buttons {
    font-size: 17px !important;
    padding: 4px 15px !important;
    margin-right: 3px !important;
  }

  .d-flex .ipad-positions {
    font-size: 15px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 12px !important;
  }

  .add-cofounder-button {
    font-size: 30px !important;
    top: 5px !important;
  }

  .add-cofounder-border {
    width: 100% !important;
  }

  .investor-page-title {
    font-size: 30px !important;
  }

  // .ml-25 h4,
  .add-cofounder-card .add-cofounder-text,
  .dashboard-container-preview h1,
  .container-preview-investor h1 {
    font-size: 17px !important;
  }

  .ipad-toggle .investor-profile-edit-title,
  .px-5 .investor-profile-edit-title,
  .investor-web-team h3,
  .dashboard-container-preview h2,
  .container-preview-investor h2 {
    font-size: 18px !important;
  }

  .card-text h5 {
    font-size: 16px !important;
  }

  .investor-profile-edit-text,
  .pb-4 .investor-profile-edit-text,
  .profile-edit .investor-web-team .pt-2 {
    font-size: 15px !important;
  }

  .card-text .investor-profile-edit-text .checkbox span,
  .pb-4 .investor-save-button,
  .pb-4 .investor-preview-button,
  .pb-4 .investor-edit-button,
  .pb-4 .investor-save-button,
  .investor-add-button,
  .px-5 .investor-edit-change-button,
  .card-body .investor-edit-change-button,
  .card-text .remove-file-button {
    font-size: 13px !important;
  }

  .dashboard-container-preview h4,
  .container-preview-investor h4 {
    font-size: 14px !important;
  }

  .profile-header {
    width: 87%;
  }

  .investor-web-list,
  .investor-web-team h6,
  .remove-cofounder-button,
  .dashboard-container-preview h5,
  .container-preview-investor h5,
  .container-preview-investor a,
  .dashboard-container-preview a,
  .mobile-seemore,
  .slide-deck-size {
    font-size: 12px !important;
  }

  .investor-profile-pic {
    height: 90px;
    width: 90px;
  }

  .ml-25 img {
    width: 63px !important;
  }

  .investor-linkedin-icon {
    width: 55px !important;
    height: 55px !important;
  }

  .ipad-profile-text {
    position: relative;
    top: 3px;
  }

  .table-header {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 600px) {
  .add-cofounder-border {
    width: 120% !important;
    margin-left: -10% !important;
  }
}

@media only screen and (max-width: 375px) {
  .attachment-size {
    .investor-profile-edit-text {
      font-size: 13px !important;
    }
  }

  .replace-sizing {
    font-size: 12px !important;
  }

  .mobile-row {
    .remove-file-button {
      font-size: 16px !important;
    }
  }

  .table-header {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .add-cofounder-card {
    width: 440px !important;
    margin: auto;
  }
}

@media only screen and (max-width: 375px) {
  .replace-sizing {
    width: 130px;
    text-align: center;
    right: 0% !important;
  }
}

@media only screen and (max-width: 360px) {
  .investor-img {
    width: 80px !important;
    height: 80px !important;
  }
  .float-right {
    .investor-profile-edit-text {
      width: 120px !important;
      right: 0% !important;
    }
  }
  .replace-sizing {
    right: 5% !important;
  }

  .mobile-preview-right {
    .font-20 {
      font-size: 18px !important;
    }
    .ml-3 {
      font-size: 15px !important;
    }

    .py-1 {
      font-size: 9px !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .replace-sizing {
    right: 20% !important;
  }
  .attachment-size {
    .investor-profile-edit-text {
      font-size: 10px !important;
    }
  }

  .replace-sizing {
    font-size: 9px !important;
  }

  .mobile-row {
    .remove-file-button {
      font-size: 14px !important;
    }
  }

  .add-cofounder-border {
    width: 125% !important;
    margin-left: -12.5% !important;
  }

  .investor-img {
    width: 65px !important;
    height: 65px !important;
  }

  .mobile-preview-right {
    .font-20 {
      font-size: 17px !important;
    }
    .ml-3 {
      font-size: 14px !important;
    }

    .py-1 {
      font-size: 8px !important;
    }
  }

  .mobile-preview-header,
  .mobile-location-size {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 280px) {
  .replace-sizing {
    right: 32% !important;
  }
}

@media only screen and (max-width: 767px) {
  div.failure-alert#startup-profile-edit-capital-alert {
    margin-bottom: 25px;

    span.alert-exclamation {
      font-size: 32px;
      line-height: 32px;
    }

    span.alert-description {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 768px) {
  div.failure-alert#startup-profile-edit-capital-alert {
    margin-top: 20px;
    margin-bottom: 0px;

    span.alert-exclamation {
      font-size: 24px;
      line-height: 24px;
    }

    span.alert-description {
      font-size: 14px;
    }
  }
}
