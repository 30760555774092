.startup-name {
  font-size: 20px !important;
  line-height: 26px;
  margin-bottom: 16px !important;
  margin-top: 20px !important;
  color: #504f4f !important;
  font-weight: 400 !important;
  &:first-child {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 520px) {
  .invest {
    width: 88% !important;
  }
}

div.failure-alert#startup-capital-alert {
  margin-top: 25px;

  span.alert-exclamation {
    font-size: 32px;
    line-height: 32px;
  }

  span.alert-description {
    font-size: 13px;
  }
}

.text-field {
  color: black !important;
}
