.investor-modal-preview {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  max-height: 545px;
  height: 100%;
  max-width: 350px;
  width: 90%;
  margin: auto;
  letter-spacing: 0px !important;
}
.content-left {
  width: 30%;
  height: 430px;

  float: left;
  margin: 40px 80px;
}

.investor-name {
  color: #162642;
  font-size: 35px;
  font-weight: bold;
  text-align: left;
  margin-top: -7px;
  margin-bottom: -4px;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeSpeed;
  letter-spacing: 0px !important;
  font-family: Roboto;
}
.investor-address {
  font-size: 18px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.investor-category {
  height: 60px;
  margin-top: -8px;
}
.category-content {
  border: 1px solid black;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  height: 30px;
  margin: 18px 0px;
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
}

.traction-each-content-preview {
  border: 2px solid #162642;
  border-radius: 30px;
  margin: 5px 5px 5px 0px;
  float: left;
  padding: 5px 15px;
  height: 36px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: #162642;
}
.prior-invest-content {
  display: flex;
  justify-content: space-between;

  width: 500px;
}
.prior-invest-each {
  height: 100px;
  width: 100px;
  text-align: center;
}
.prior-invest-logo-each {
  height: 80px;
  width: 80px;
  border-radius: 5px;
}
.prior-invest-name-each {
  color: #162642;
  font-size: 20px;
  font-weight: bold;
}
.investor-brief {
  text-align: left;
  font-size: 18px;
  margin-top: 10px;
  line-height: 140%;

  width: 300px;
  height: 130px;
}
.investor-affliation-container {
  width: 400px;
  height: 200px;
}
.affliation {
  font-size: 30px;
  font-weight: bold;
  font-family: Roboto;
  padding-bottom: 5px;
}
.afflication-each {
  font-size: 22px;
  font-family: Roboto;
}

.content-right {
  width: 52%;
  height: 310px;

  float: left;
  margin-top: 50px;
}
.investor-invest {
  height: 60px;
}
.investor-sectors {
  height: 200px;
}

.investor-sector-title {
  font-size: 27px;
  font-weight: bold;
  margin-top: 30px;
}

.prior-investment {
  height: 150px;
  margin-top: -20px;
}

.prior-investment-title {
  font-size: 27px;
  font-weight: bold;
  font-family: Roboto;
}

.bottom {
  height: 85px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
}

.button-left {
  width: 590px;
  border: none;
  border-top: 1px solid #162642 !important;
  background-color: white;
  border-radius: 0px 0px 0px 10px;
  color: #162642;
  font-size: 25px;
  font-family: Roboto;
  &:hover {
    transform: scale(1) !important;
  }
}

.button-right {
  width: 590px;
  background-color: #162642;
  color: white;
  font-size: 25px;
  border-radius: 0px 0px 10px 0px;
  border: 0;
  font-weight: 700;
}
