.investor-logout-page {
  margin: 0 auto;
}
.logout-title {
  font-size: 30px;
  color: #162642;
  font-weight: 700;
  font-family: Roboto;
  margin: 40px auto;
  text-align: center;
}
.logout-mid {
  font-size: 28px;
  font-family: Roboto;
  text-align: center;
  margin-top: -35px;
}

.redirect {
  text-align: center;
  margin-top: 68px;
  font-size: 18px;
  color: #162642;
  font-weight: bold;
}
